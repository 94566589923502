import { useSearchParams } from "react-router-dom"
import TxTable from "../../../components/tables/transactions-table/table"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"

const TransactionsBody = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	return (
		<CenteredTableBody>
			<TxTable
				transaction_id={searchParams.get("transaction_id") ?? undefined}
				payment_id={searchParams.get("payment_id") ?? undefined}
				showFilters
				scrollable
				viewOptions
				onCloseModal={() => {
					setSearchParams({})
				}}
			/>
		</CenteredTableBody>
	)
}

export default TransactionsBody
