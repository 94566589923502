import { useEffect, useRef, useState } from "react"
import { useAuth } from "../../../context/AuthContext"
import useBreakpoint, { ScreenBreakpoint } from "../../../hooks/useBreakpoint"
import { useCombinedPayments } from "../../../hooks/usePayments"
import PaymentModal from "../../modals/payment-modal/PaymentModal"
import { DataTable } from "../../ui/data-table/data-table"
import { TableTransaction, columns } from "./columns"
import { tableTransactionCurrencies } from "./columns/currencyImageCell"
import { tableTransactionStatus } from "./columns/statusCell"

export const ROW_HEIGHT = 70
export const rowHeightClassName = "h-[70px]"

const TxTable = ({
	showFilters = false,
	scrollable = false,
	viewOptions = false,
	bordered = false,
	onCloseModal,
	transaction_id,
	payment_id,
}: {
	viewOptions?: boolean
	showFilters?: boolean
	scrollable?: boolean
	bordered?: boolean
	onCloseModal?: () => void
	transaction_id?: string
	payment_id?: string
}) => {
	const tableContainerRef = useRef<HTMLDivElement>(null)
	const { user } = useAuth()
	const [visibleRowCount, setVisibleRowCount] = useState(0)
	const [selectedPayment, setSelectedPayment] =
		useState<TableTransaction | null>(null)
	const [modalOpen, setModalOpen] = useState(!!transaction_id)

	const { data: payments, isLoading } = useCombinedPayments(
		user.id,
		user.roles.includes("pos") ? user.team_id : undefined
	)
	useEffect(() => {
		if (payments && transaction_id) {
			const selected = payments.find(
				(item: any) => `TX#${transaction_id}` === item.id
			)
			if (selected) {
				setSelectedPayment(selected)
				setModalOpen(true)
			}
		}
		if (payments && payment_id) {
			const selected = payments.find((item: any) => payment_id === item.id)
			if (selected) {
				setSelectedPayment(selected)
				setModalOpen(true)
			}
		}
	}, [payments, transaction_id, payment_id])

	useEffect(() => {
		const calculateVisibleRows = () => {
			if (!scrollable && tableContainerRef.current) {
				const containerHeight = tableContainerRef.current.clientHeight
				console.log(`not scrollable. containerHeight:${containerHeight}`)
				const visibleRows = Math.floor(containerHeight / ROW_HEIGHT)
				if (visibleRows > 1) {
					setVisibleRowCount(visibleRows - 1)
				}
				console.log(visibleRowCount)
			} else if (scrollable) {
				const containerHeight = tableContainerRef?.current?.clientHeight
				console.log(`scrollable. containerHeight:${containerHeight}`)
				setVisibleRowCount(payments?.length ?? 0)
			}
		}

		calculateVisibleRows()
		window.addEventListener("resize", calculateVisibleRows)
		return () => window.removeEventListener("resize", calculateVisibleRows)
	}, [payments, tableContainerRef.current?.clientHeight, scrollable])

	const breakpoint = useBreakpoint()

	return (
		<div className="h-full" ref={tableContainerRef}>
			{selectedPayment && (
				<PaymentModal
					open={modalOpen}
					onClose={() => {
						setModalOpen(false)
						onCloseModal && onCloseModal()
					}}
					payment={selectedPayment}
				/>
			)}
			<DataTable
				loading={isLoading}
				viewOptions={viewOptions}
				rowHeightClass={rowHeightClassName}
				columns={columns[breakpoint as ScreenBreakpoint]}
				data={payments?.slice(0, visibleRowCount) ?? []}
				variant={bordered ? "bordered" : "default"}
				searchFilter={
					showFilters
						? {
								placeholder: "Id de orden...",
								columnName: "orderId",
						  }
						: undefined
				}
				onRowSelected={(row) => {
					setSelectedPayment(row)
					setModalOpen(true)
				}}
				filters={
					showFilters
						? [
								{
									column: "status",
									title: "Estado",
									options: tableTransactionStatus.map((status) => ({
										value: status,
										label: status,
									})),
								},
								{
									column: "currency",
									title: "Moneda",
									options: tableTransactionCurrencies.map((curr) => ({
										value: curr,
										label: curr,
									})),
								},
						  ]
						: undefined
				}
			/>
		</div>
	)
}

export default TxTable
