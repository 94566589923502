import { Info } from "lucide-react"
import { WithdrawData } from "../../../pages/withdraw/withdraw-wizard-types"
import { Alert } from "../../ui/alert"
import Col from "../../ui/layouts/column"
import Column from "../../ui/layouts/column"
import Row from "../../ui/layouts/row"
import { SectionHeader } from "../../ui/section-header"
import { Separator } from "../../ui/separator"
import {
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from "../../ui/sheet"
import { Heading2, Heading6 } from "../../ui/texts/TextStyle"
import BodyItem from "../payment-modal/body/BodyItem"
import { formatAmountAndCurrency } from "./WithdrawConfirmationModal"
import { Button } from "../../ui/button"
import { findDisplayNameForBankInJson } from "../../../lib/utils"
import bank_list from "../../../lib/psp_bank_id.json"

interface CvuSheetContentProps {
	data: WithdrawData
	handleConfirm: () => void
}

export default function CvuSheetContent({
	data,
	handleConfirm,
}: CvuSheetContentProps) {
	return (
		<SheetContent side="right">
			<div className="grid grid-cols-1 items-center">
				<SheetHeader className="py-betweenComponents">
					<SheetTitle className="pb-betweenSections">
						<SectionHeader title="Confirmá tu envío" />
					</SheetTitle>

					<SheetDescription>
						<Column>
							<Heading6 className="text-text-muted font-medium">
								Recibirás
							</Heading6>
							<Row className="gap-betweenChips">
								<img
									src={
										data.fiatAddressDetails?.icon_img
											? data.fiatAddressDetails?.icon_img
											: `/currencies/${data?.currency?.toLowerCase()}-icon.svg`
									}
									alt=""
								/>
								<Heading2 className="font-normal text-text">
									{formatAmountAndCurrency(
										data.amount?.amount as string,
										data.amount?.currency!,
										false
									)}
								</Heading2>
							</Row>
						</Column>
					</SheetDescription>
				</SheetHeader>
				<Separator orientation="horizontal" />
				<Col>
					<BodyItem name="CVU" value={data.address ?? ""} overflow />
					<BodyItem
						name="Banco"
						value={
							data.fiatAddressDetails?.bank_id
								? findDisplayNameForBankInJson(
										data.fiatAddressDetails?.bank_id,
										bank_list
								  )
								: data.fiatAddressDetails?.psp_id
								? findDisplayNameForBankInJson(
										data.fiatAddressDetails?.psp_id,
										bank_list
								  )
								: ""
						}
					/>
					<BodyItem
						name="Monto total"
						value={formatAmountAndCurrency(
							data.amount?.amount as string,
							data?.currency as string,
							false
						)}
					/>

					<Alert
						icon={<Info />}
						title="Cuidado"
						variant="warning"
						description="Asegurate que el monto y la dirección de destino sean correctos."
					/>
				</Col>
				<SheetFooter className="py-betweenSections">
					<Button size="full" onClick={handleConfirm}>
						Confirmar
					</Button>
				</SheetFooter>
			</div>
		</SheetContent>
	)
}
