import { useEffect, useState } from "react"
import { useAuth } from "../../../../context/AuthContext"
import { getFormattedStoreUrl, isTransferOrCrypto } from "../../../../lib/utils"
import { TableTransaction } from "../../../tables/transactions-table/columns"
import SuccessPaymentSummary from "./sections/SuccessPaymentSummary"
import SuccessPaymentTransactionDetails from "./sections/SuccessPaymentTransactionDetails"
import TiendanubePaymentDetails from "./sections/TiendanubePaymentDetails"
import WoocommercePaymentDetails from "./sections/WoocommercePaymentDetails"

const SuccessPaymentModalBody = ({
	payment,
}: {
	payment: TableTransaction
}) => {
	const { stores } = useAuth()
	const [tiendanubeStore, setTiendanubeStore] = useState<any>()
	const [woocommerceStore, setWoocommerceStore] = useState<any>()

	useEffect(() => {
		if (!stores || !payment) return

		const tiendanubeStore = stores.find(
			(store: any) => store.store_id === payment.tiendanube?.store_id
		)
		const woocommerceStore = stores.find(
			(store: any) => store.store_id === payment.woocommerce?.store_id
		)

		setTiendanubeStore(tiendanubeStore)
		setWoocommerceStore(woocommerceStore)
	}, [stores, payment])
	let variant : "crypto" | "transfer" | undefined = isTransferOrCrypto(payment.payment_options)
	return (
		<div className="grid grid-cols-1 items-center">
			<SuccessPaymentSummary
				payment={payment}
				variant={variant}
			/>
			<SuccessPaymentTransactionDetails
				payment={payment}
				variant={variant}
			/>
			{payment.tiendanube && (
				<TiendanubePaymentDetails
					info={payment.tiendanube}
					store_url={getFormattedStoreUrl(tiendanubeStore?.store_url)}
				/>
			)}
			{payment.woocommerce && (
				<WoocommercePaymentDetails
					info={payment.woocommerce}
					store_url={getFormattedStoreUrl(woocommerceStore?.store_url)}
				/>
			)}
		</div>
	)
}

export default SuccessPaymentModalBody
