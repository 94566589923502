import { QRCodeSVG } from "qrcode.react"
import { TableTransaction } from "../../../tables/transactions-table/columns"
import { Input } from "../../../ui/inputs/input"
import BodyBlock from "./BodyBlock"
import { Label } from "../../../ui/label"
import Col from "../../../ui/layouts/column"
import BodyItem from "./BodyItem"
import { formatPriceAmount } from "../../../../utils/numbers"
import DateInfo from "./DateInfo"
import { getFormattedStoreUrl } from "../../../../lib/utils"
import TiendanubePaymentDetails from "./sections/TiendanubePaymentDetails"
import WoocommercePaymentDetails from "./sections/WoocommercePaymentDetails"
import { useState, useEffect } from "react"
import { useAuth } from "../../../../context/AuthContext"

const PendingPaymentModalBody = ({
	payment,
	variant,
}: {
	payment: TableTransaction
	variant?: "transfer" | "crypto"
}) => {
	const { stores } = useAuth()
	const [tiendanubeStore, setTiendanubeStore] = useState<any>()
	const [woocommerceStore, setWoocommerceStore] = useState<any>()

	useEffect(() => {
		if (!stores || !payment) return

		const tiendanubeStore = stores.find(
			(store: any) => store.store_id === payment.tiendanube?.store_id
		)
		const woocommerceStore = stores.find(
			(store: any) => store.store_id === payment.woocommerce?.store_id
		)

		setTiendanubeStore(tiendanubeStore)
		setWoocommerceStore(woocommerceStore)
	}, [stores, payment])

	switch (variant) {
		case "transfer":
			// For 'transfer', remove the QR code
			return (
				<>
					<BodyBlock title="Orden de compra">
						{payment.expiration_timestamp && (
							<DateInfo
								dateAlternativeTitle="Fecha de Vencimiento"
								timeAlternativeTitle="Horario de Vencimiento"
								date={payment.expiration_timestamp.date}
								time={payment.expiration_timestamp.time}
							/>
						)}
						<BodyItem
							name="Monto"
							value={`${formatPriceAmount(
								payment.price?.amount,
								undefined,
								payment.price?.currency
							)} ${payment.price?.currency}`}
						/>
					</BodyBlock>
					<BodyBlock title={"Destino"}>
						{payment.alias && (
							<BodyItem name="Alias" value={payment.alias} copy={true} />
						)}
						<BodyItem
							name="CVU"
							value={payment.address}
							copy={true}
							overflow={true}
						/>

						{payment.paymentUrl && (
							<BodyItem
								name="Link de pago"
								value={payment.paymentUrl}
								copy={true}
								url={true}
							/>
						)}
					</BodyBlock>
					{payment.tiendanube && (
						<TiendanubePaymentDetails
							info={payment.tiendanube}
							store_url={getFormattedStoreUrl(tiendanubeStore?.store_url)}
						/>
					)}
					{payment.woocommerce && (
						<WoocommercePaymentDetails
							info={payment.woocommerce}
							store_url={getFormattedStoreUrl(woocommerceStore?.store_url)}
						/>
					)}
				</>
			)
		case "crypto":
		default:
			// For 'crypto' and default, keep the QR code
			return (
				<BodyBlock title="Escaneá el código para acceder al link de pago:">
					{payment.paymentUrl && (
						<div className="flex flex-col items-center mx-auto h-6/10 w-full">
							<span className="w-full flex justify-center py-betweenComponents">
								<QRCodeSVG
									level="M"
									value={payment.paymentUrl}
									className="h-4/5 max-w-[400px] max-h-[400px] w-full"
								/>
							</span>
							{/* <CopyButton textToCopy={payment.paymentUrl} /> */}
							<div className="w-full flex flex-row py-betweenComponents items-center">
								<Input type="copy" value={payment.paymentUrl} />
								{/* <div className="flex-grow flex flex-row items-center border border-dashed border-border-input rounded-sm p-betweenTexts max-w-[calc(100%-24px)]">
							<Link size={24} />
							<div className="flex-grow  overflow-hidden pl-betweenTexts">
								<Heading6 className="overflow-ellipsis overflow-hidden font-normal ">
									{payment.paymentUrl}
								</Heading6>
							</div>
						</div>
						<CopyButton textToCopy={payment.paymentUrl} /> */}
							</div>
						</div>
					)}
				</BodyBlock>
			)
	}
}

export default PendingPaymentModalBody
