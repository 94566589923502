import { Bell, CodeXml, ShoppingBag } from "lucide-react"
import React from "react"
import { Route } from "react-router-dom"
import ProtectedRoute from "../../components/routes/ProtectedRoute"
import ResponsiveLayout from "../../components/ui/layouts/platform/ResponsiveLayout"
import { PrivateRole, PublicRole } from "../../lib/roles"
import SettingsBody from "./SettingsBody"
import NotificationsBody from "./notifications/NotificationsBody"
import PaymentMethodsBody from "./payment-methods/PaymentMethodsBody"
import PricingProfileBody from "./payment-methods/PricingProfileBody"
import { paymentMethodsSubSections } from "./payment-methods/items"
import ApiBody from "./plugins/ApiBody"
import CredentialsBody from "./plugins/CredentialsBody"
import PluginsBody from "./plugins/PluginsBody"
import TiendanubeBody from "./plugins/TiendanubeCryptoBody"
import TiendanubeCvuBody from "./plugins/TiendanubeCvuBody"
import WoocommerceBody from "./plugins/WoocommerceBody"

export type SettingsRouteItem = {
	itemName: string
	itemLink: string
	minimumPublicRole: PublicRole
	minimumPrivateRole: PrivateRole
	bodyComponent: React.ReactNode
	smBodyComponent: React.ReactNode
	featureFlag?: any
}

export type SettingsSectionItem = {
	title: string
	description?: string
	minimumPublicRole?: PublicRole
	minimumPrivateRole?: PrivateRole
	featureFlag?: any
	subItems: SettingsSectionSubItem[]
}

type SettingsSectionSubItem = {
	title: string
	description?: string
	subleading?: string
	icon: React.ReactNode
	link: string
	minimumPublicRole?: PublicRole
	minimumPrivateRole?: PrivateRole
	bodyComponent: React.ReactNode
	smBodyComponent: React.ReactNode
	subSections?: SettingsSectionItem[]
	iconInside?: boolean
	featureFlag?: any
	flaggedTitle?: string
	flaggedMethodState?: string
}

const settingsItems = [
	{
		itemName: "Ajustes",
		itemLink: "/settings",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <SettingsBody />,
		smBodyComponent: <SettingsBody />,
	},
]

export const settingsSections: SettingsSectionItem[] = [
	{
		title: "Checkout",
		description: "Personalizá la experiencia de pago de tus clientes",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Métodos de pago",
				description: "Elegí las opciones de pago de tus clientes",
				icon: <ShoppingBag />,
				link: "/settings/payment-methods",
				bodyComponent: (
					<PaymentMethodsBody subSections={paymentMethodsSubSections} />
				),
				smBodyComponent: (
					<PaymentMethodsBody subSections={paymentMethodsSubSections} />
				),
				minimumPublicRole: PublicRole.SUPERVISOR,
				subSections: paymentMethodsSubSections,
			},
		],
	},
	{
		title: "Notificaciones",
		description: "Administrá tus notificaciones de pago",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Notificación de pago",
				description: "Configurá tus notificaciones",
				link: "/settings/notifications",
				icon: <Bell />,
				bodyComponent: <NotificationsBody />,
				smBodyComponent: <NotificationsBody />,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
		],
	},
	// {
	// 	title: "Plataforma",
	// 	description: "Administrá las opciones de la plataforma",
	// 	minimumPublicRole: PublicRole.SUPERVISOR,
	// 	minimumPrivateRole: PrivateRole.JEDI,
	// 	subItems: [
	// 		{
	// 			title: "Modo Sandbox",
	// 			description:
	// 				"Experimentá funciones de la plataforma usando fondos de prueba",
	// 			icon: <TestTube />,
	// 			link: "/settings/sandbox",
	// 			bodyComponent: <></>,
	// 			smBodyComponent: <></>,
	// 			smHeaderContent: <></>,
	// 		},
	// 	],
	// },
]

export function mapSettingsItemToRouteItem(
	settingsItem: SettingsSectionSubItem
): SettingsRouteItem {
	return {
		itemName: settingsItem.title,
		itemLink: settingsItem.link,
		minimumPublicRole:
			settingsItem.minimumPublicRole ??
			// item.minimumPublicRole ??
			PublicRole.OWNER,
		minimumPrivateRole:
			settingsItem.minimumPrivateRole ??
			// item.minimumPrivateRole ??
			PrivateRole.YODA,
		bodyComponent: settingsItem.bodyComponent,
		smBodyComponent: settingsItem.smBodyComponent,
		featureFlag: settingsItem.featureFlag,
	}
}

const subItemRoutes: SettingsRouteItem[] = settingsSections
	.map((item) =>
		item.subItems
			.filter((item) => !item.link.startsWith("http"))
			.flatMap((subItem) => {
				const subItems =
					subItem.subSections?.flatMap((subS) =>
						subS.subItems.map(mapSettingsItemToRouteItem)
					) ?? []
				return [mapSettingsItemToRouteItem(subItem), ...subItems]
			})
	)
	.flat()

const transferPluginSubSections: SettingsSectionItem[] = [
	{
		title: "Transferencias",
		description: "Transferencias automáticas",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/settings/payment-methods/transfer/plugins/${process.env.REACT_APP_TIENDANUBE_CVU_APP_ID}`,
				bodyComponent: <TiendanubeCvuBody />,
				smBodyComponent: <TiendanubeCvuBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
			{
				title: "API",
				icon: <CodeXml />,
				link: "/settings/payment-methods/transfer/plugins/api",
				bodyComponent: <ApiBody />,
				smBodyComponent: <ApiBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
		],
	},
]

const cryptoPluginSubSections: SettingsSectionItem[] = [
	{
		title: "Monedas Digitales",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/settings/payment-methods/crypto/plugins/${process.env.REACT_APP_TIENDANUBE_APP_ID}`,
				bodyComponent: (
					<TiendanubeBody appId={process.env.REACT_APP_TIENDANUBE_APP_ID} />
				),
				smBodyComponent: (
					<TiendanubeBody appId={process.env.REACT_APP_TIENDANUBE_APP_ID} />
				),
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
				featureFlag: "show_tn_legacy",
			},
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/settings/payment-methods/crypto/plugins/${process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}`,
				bodyComponent: (
					<TiendanubeBody
						appId={process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}
					/>
				),
				smBodyComponent: (
					<TiendanubeBody
						appId={process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}
					/>
				),
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
				featureFlag: "show_tn_homo",
			},
			{
				title: "WooCommerce",
				icon: (
					<img src="/dashboard/plugins/woocommerce.svg" alt="woocommerce" />
				),
				link: "/settings/payment-methods/crypto/plugins/woocomerce",
				bodyComponent: <WoocommerceBody />,
				smBodyComponent: <WoocommerceBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
			{
				title: "API",
				icon: <CodeXml />,
				link: "/settings/payment-methods/crypto/plugins/api",
				bodyComponent: <ApiBody />,
				smBodyComponent: <ApiBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
		],
	},
]

const pixPluginSubSections: SettingsSectionItem[] = [
	{
		title: "Reales",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/settings/payment-methods/pix/plugins/${process.env.REACT_APP_TIENDANUBE_APP_ID}`,
				bodyComponent: (
					<TiendanubeBody appId={process.env.REACT_APP_TIENDANUBE_APP_ID} />
				),
				smBodyComponent: (
					<TiendanubeBody appId={process.env.REACT_APP_TIENDANUBE_APP_ID} />
				),
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
				featureFlag: "show_tn_legacy",
			},
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/settings/payment-methods/pix/plugins/${process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}`,
				bodyComponent: (
					<TiendanubeBody
						appId={process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}
					/>
				),
				smBodyComponent: (
					<TiendanubeBody
						appId={process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}
					/>
				),
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
				featureFlag: "show_tn_homo",
			},
			{
				title: "WooCommerce",
				icon: (
					<img src="/dashboard/plugins/woocommerce.svg" alt="woocommerce" />
				),
				link: "/settings/payment-methods/pix/plugins/woocomerce",
				bodyComponent: <WoocommerceBody />,
				smBodyComponent: <WoocommerceBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
			{
				title: "API",
				icon: <CodeXml />,
				link: "/settings/payment-methods/pix/plugins/api",
				bodyComponent: <ApiBody />,
				smBodyComponent: <ApiBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
		],
	},
]
const credentialsRouteItem: SettingsRouteItem = {
	itemName: "Credenciales",
	itemLink: "/settings/plugins/credentials",
	bodyComponent: <CredentialsBody />,
	smBodyComponent: <CredentialsBody />,
	minimumPublicRole: PublicRole.OWNER,
	minimumPrivateRole: PrivateRole.JEDI,
}

const pricingProfileRouteItem: SettingsRouteItem = {
	itemName: "Cotización dólar",
	itemLink: "/settings/payment-methods/pricing-profile",
	bodyComponent: <PricingProfileBody />,
	smBodyComponent: <PricingProfileBody />,
	minimumPublicRole: PublicRole.SUPERVISOR,
	minimumPrivateRole: PrivateRole.JEDI,
}

const mappedTransferPluginSubSections = transferPluginSubSections.flatMap(
	(item) => item.subItems.map(mapSettingsItemToRouteItem)
)

const mappedCryptoPluginSubSections = cryptoPluginSubSections.flatMap((item) =>
	item.subItems.map(mapSettingsItemToRouteItem)
)

const mappedPixPluginSubSections = pixPluginSubSections.flatMap((item) =>
	item.subItems.map(mapSettingsItemToRouteItem)
)

const paymentMethodIntegrations: SettingsRouteItem[] = [
	{
		itemName: "Integraciones",
		itemLink: "/settings/payment-methods/transfer/plugins",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <PluginsBody subSections={transferPluginSubSections} />,
		smBodyComponent: <PluginsBody subSections={transferPluginSubSections} />,
	},
	{
		itemName: "Integraciones",
		itemLink: "/settings/payment-methods/pix/plugins",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <PluginsBody subSections={pixPluginSubSections} />,
		smBodyComponent: <PluginsBody subSections={pixPluginSubSections} />,
	},
	{
		itemName: "Integraciones",
		itemLink: "/settings/payment-methods/crypto/plugins",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <PluginsBody subSections={cryptoPluginSubSections} />,
		smBodyComponent: <PluginsBody subSections={cryptoPluginSubSections} />,
	},
]

const allSettingsItems = [
	...settingsItems,
	...subItemRoutes,
	...paymentMethodIntegrations,
	...mappedTransferPluginSubSections,
	...mappedCryptoPluginSubSections,
	...mappedPixPluginSubSections,
	credentialsRouteItem,
	pricingProfileRouteItem,
]

export const settingsRoutes = allSettingsItems.map((item) => (
	<Route
		key={item.itemLink}
		path={item.itemLink}
		element={
			<ProtectedRoute
				requiresVerification={true}
				minimumPrivateRole={item.minimumPrivateRole}
				minimumPublicRole={item.minimumPublicRole}
			>
				<ResponsiveLayout item={item} />
			</ProtectedRoute>
		}
	/>
))
