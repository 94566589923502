import { SettingsItemRow } from "../../../components/app/settings/SettingsItem"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import { useAuth } from "../../../context/AuthContext"
import { useFlags } from "../../../context/FlagsContext"
import { PrivateRole, PublicRole } from "../../../lib/roles"
import { SettingsSectionItem } from "../items"
import { PluginsCover } from "../payment-methods/PaymentsCover"

const PluginsBody = ({
	subSections,
}: {
	subSections: SettingsSectionItem[]
}) => {
	const { hasPrivilege } = useAuth()
	const { getFlag } = useFlags()
	return (
		<FeatureLayout
			coverImage={<PluginsCover />}
			sectionHeaderProps={{
				title: "Conectá con tu tienda online",
				subtitle:
					"Aca podrás conectar tu cuenta de talo con tu tienda online, en cualquiera de las plataformas disponibles.",
			}}
		>
			{subSections
				.filter(
					(subs) =>
						hasPrivilege(
							subs.minimumPrivateRole ?? PrivateRole.CLONE,
							subs.minimumPublicRole ?? PublicRole.POS
						) &&
						subs.subItems.some((subItem) =>
							hasPrivilege(
								subItem.minimumPrivateRole ?? PrivateRole.CLONE,
								subItem.minimumPublicRole ?? PublicRole.POS
							)
						)
				)
				.map((section) => (
					<Section
						title={section.title}
						footer={section.description}
						key={section.title}
					>
						{section.subItems
							.filter(
								(subItem) =>
									hasPrivilege(
										subItem.minimumPrivateRole ?? PrivateRole.CLONE,
										subItem.minimumPublicRole ?? PublicRole.POS
									) &&
									(!subItem.featureFlag ||
										(getFlag(subItem.featureFlag)
											? getFlag(subItem.featureFlag)._value == 'true'
											: true))
							)
							.map((item) => (
								<SettingsItemRow
									link={item.link}
									key={item.title}
									title={item.title}
									icon={item.icon}
									iconInside={item.iconInside}
								/>
							))}
					</Section>
				))}

			{/* <Section title={"Plataformas disponibles"}>
				<Option
					title="Monedas disponibles"
					leading="arrow"
					icon={<Coins />}
				></Option>
			</Section> */}
			{/* <Section title={"Conectá tu pagina web (avanzado)"}>
				<Option
					title="Conectate a nuestra API"
					leading="arrow"
					onClick={() =>
						(window.location.href =
							"https://docs.talo.com.ar/ecommerce/API/getting_started")
					}
					icon={<CodeXml />}
				></Option>
				<Option title="Credenciales" leading="arrow" icon={<Key />}></Option> 
			</Section>*/}
		</FeatureLayout>
	)
}

export default PluginsBody
