import { ColumnDef } from "@tanstack/react-table"
import { PaymentComission } from "../../modals/payment-modal/PaymentModal"
import { CreditedMoneyColumn } from "./columns/creditedMoneyColumn"
import {
	CurrencyImageCell,
	tableTransactionCurrencies,
} from "./columns/currencyImageCell"
import { DateAndTimeCell } from "./columns/dateAndTimeCell"
import { StatusColumn, tableTransactionStatus } from "./columns/statusCell"
import { MoneyCell } from "./columns/MoneyCell"
import { OrderCell } from "./columns/OrderCell"
import { PaymentOption } from "../../../pages/charge/charge-wizard-types"

export type Price = {
	amount: number | string
	currency: TableTransactionCurrency
}
export type TableTransactionStatus = (typeof tableTransactionStatus)[number]
export type TableTransactionCurrency =
	(typeof tableTransactionCurrencies)[number]

export type TableTransactionNetwork = "BSC" | "POLYGON" | "PIX" | "POLLUX"

export type TableSubTransaction = {
	network: TableTransactionNetwork
	currency: TableTransactionCurrency
	amount: number | string
	amountReadable: string
	transactionHash: string
	transactionId: string
	senderAddress: string
	senderIsPsp: boolean
	psp_id?: string
	bank_id?: string
	date: string
	time: string
	senderCuit?: string
}

export type TiendanubePaymentInfo = {
	order_id: number
	order_number: number
	client_email?: string
	store_id: string
}

export type WoocommercePaymentInfo = {
	order_id: number
	client_email?: string
	store_id: string
}

export type TableTransaction = {
	id: string
	orderId?: string | number
	currency?: (typeof tableTransactionCurrencies)[number]
	status: (typeof tableTransactionStatus)[number]
	lastModifiedDateTime: {
		date: string
		time: string
	}
	receivedInRequestedCurrency?: Price
	sender?: string
	sender_cuit?: string
	buyer?: string
	expected?: Price
	received?: Price
	credited?: Price
	creditedInRequestedCurrency?: Price
	price?: Price
	comission?: PaymentComission
	network?: TableTransactionNetwork
	address?: string
	alias?: string
	transactions?: TableSubTransaction[]
	transactionHash?: string
	tiendanube?: TiendanubePaymentInfo
	woocommerce?: WoocommercePaymentInfo
	paymentUrl?: string
	psp_id?: string
	bank_id?: string
	expiration_timestamp?: {
		date: string
		time: string
	}
	payment_options?: PaymentOption[]
	recipient_account?:RecipientAccount | undefined
}

export type RecipientAccount = {
	account_type: string;
	active_account: boolean;
	address: string;
	alias: string;
	creation_timestamp: string;
	cuit: string;
	cvu_type: string;
	entity_type: string;
	last_modified_timestamp: string;
	last_used_timestamp: string;
	name: string;
	psp_id: string;
	user_id: string;
  }

export const columns: { [key: string]: ColumnDef<TableTransaction>[] } = {
	xl: [
		StatusColumn(),

		OrderCell({
			accessorKey: "orderId",
			header: "Orden",
		}),
		MoneyCell({
			accessorKey: "expected",
			header: "Solicitado",
		}),
		MoneyCell({
			accessorKey: "received",
			header: "Recibido",
		}),
		CurrencyImageCell(),

		DateAndTimeCell(),
	],
	lg: [
		StatusColumn(),
		OrderCell({
			accessorKey: "orderId",
			header: "Orden",
		}),

		MoneyCell({
			accessorKey: "received",
			header: "Recibido",
		}),
		CurrencyImageCell(),
		DateAndTimeCell(),
	],
	md: [StatusColumn(), CreditedMoneyColumn()],
	sm: [StatusColumn(), CreditedMoneyColumn()],
}
