import { Scale, Store, User } from "lucide-react"
import { useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../components/ui/alert"
import LabelButton from "../../components/ui/buttons/LabelButton"
import Col from "../../components/ui/layouts/column"
import PlatformHeader from "../../components/ui/layouts/platform/PlatformHeader"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../components/ui/section-header"
import { useAuth } from "../../context/AuthContext"
import { platformHeader } from "../../components/ui/layouts/layout-sizes"

const onboardingTypeLabels = [
	{
		label: "Personal",
		path: "/onboarding/personal",
		icon: <User />,
	},
	{
		label: "Empresa",
		path: "/onboarding/empresa",
		icon: <Store />,
	},
]

export default function OnboardingBody() {
	const { user, kyc } = useAuth()

	const navigate = useNavigate()

	//Usamos layouteffect para que no se monte el componete innecesariamente
	useLayoutEffect(() => {
		if (!user) {
			navigate("/login")
		}
		//Si el usuario ya completo el onboarding lo redirigimos a la home
		switch (kyc.data?.kyc_status) {
			case "approved":
				if (user.pollux_account_id) {
					navigate("/")
				} else {
					navigate("/onboarding/personal")
				}
				break
			case "rejected":
				navigate("/onboarding/personal")
				break
			case "basic_info":
			case "metamap":
				//mientras no tengamos el flujo de kyb, esta redireccion es valida, en otro momento deberiamos redirigir a la pagina de onboarding que corresponda
				navigate("/onboarding/personal")
				break
			case undefined:
				break
			default:
				navigate("/onboarding/personal")
				break
		}
	}, [user, kyc.data?.kyc_status, navigate])

	return (
		<>
			<PlatformHeader title={"Cuenta"} variant="secondary" backButton={false} />
			<CenteredBody bottom className={`${platformHeader.paddingX.class} pt-4`}>
				<SectionHeader
					title={"Seleccioná el tipo de cuenta"}
					subtitle={
						"El tipo de cuenta afectará en las facturas y en las funcionalidades de la plataforma."
					}
				/>
				<Col className="gap-betweenCards">
					<Alert
						icon={<Scale />}
						title={"Atención"}
						description={
							"Las cuentas empresa deberán ser personas jurídicas y presentar documentación adicional."
						}
					/>
					<div className=" flex-row flex gap-betweenButtons pt-contentToButton">
						{Object.entries(onboardingTypeLabels).map(([key, value]) => (
							<LabelButton
								size="full"
								key={key}
								label={value.label}
								icon={value.icon}
								variant={"outline"}
								className="group"
								onClick={() => navigate(value.path)}
							/>
						))}
					</div>
				</Col>
			</CenteredBody>
		</>
	)
}
