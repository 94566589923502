import { ArrowDownToLine, ArrowUpToLine } from "lucide-react"
import { useEffect, useState } from "react"
import { Button } from "../../components/ui/button"
import LandingLayout from "../../components/ui/layouts/landing/LandingLayout"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../components/ui/section-header"
import { Heading3 } from "../../components/ui/texts/TextStyle"

const TermsOfService: React.FC = () => {
	const [isBottom, setIsBottom] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			const bottom =
				window.innerHeight + window.scrollY >=
				document.documentElement.scrollHeight
			setIsBottom(bottom)
		}

		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])

	const scrollToBottom = () => {
		window.scrollTo({
			top: document.documentElement.scrollHeight,
			behavior: "smooth",
		})
	}
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		})
	}

	return (
		<LandingLayout>
			<CenteredBody className="h-full justify-center items-center">
				<SectionHeader
					title="Términos y Condiciones de Talo Pay S.A.S."
					subtitle="Última actualización: 10 de Marzo 2023"
				/>

				<div className="py-8 space-y-8">
					<section className="space-y-4">
						<Heading3 className="font-semibold mb-4">Introducción</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 font-normal text-justify leading-relaxed">
							Bienvenido a Talo Pay S.A.S. Al utilizar nuestros servicios, usted
							acepta estos términos y condiciones.
						</p>
						<p className="text-gray-700 dark:text-gray-400 text-h5 font-normal text-justify leading-relaxed">
							Por favor, lea atentamente los presentes términos y condiciones de
							uso (los “Términos de Uso”) que regirán la prestación de los
							servicios ofrecidos a través de la Aplicación (según dicho término
							se define más adelante) correspondientes al uso del servicio de
							procesamiento de pagos, mediante una cuenta de pago (según dichos
							términos se definen más adelante) que ofrece Talo Pay S.A.S.
							(“Talo Pay”) al Usuario (según dichos términos se definen más
							adelante). Por favor, lea atentamente estos Términos de Uso, así
							como cualquier otro documento relacionado, que comprenderán los
							acuerdos entre el Usuario y Talo Pay en la prestación de los
							Servicios (según dicho término se define más adelante) que se
							indican a continuación. AL HACER CLIC PARA ACEPTAR, O AL ACCEDER O
							USAR NUESTROS SERVICIOS (COMO SE DEFINE EN LOS PRESENTES TÉRMINOS
							DE USO), ACEPTA ESTOS TÉRMINOS DE USO EN SU TOTALIDAD, ASÍ COMO
							LOS OTROS TÉRMINOS INCORPORADOS POR REFERENCIA.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">1. Definiciones</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 font-normal text-justify leading-relaxed">
							Para efectos de los Términos de Uso, los términos con letra
							mayúscula inicial tendrán los significados que se señalan a
							continuación y las definiciones en particular se entenderán
							aplicables a aquellos términos en plural y viceversa, excepto en
							aquellas ocasiones en las que no sean conformes con el texto:
						</p>
						<ul className="list-disc list-outside pl-5 text-gray-700 dark:text-gray-400 text-justify text-h5 space-y-1 pt-4 font-normal leading-relaxed">
							<li>
								<strong>
									“Aplicación” y/o “Aplicaciones”, indistintamente:
								</strong>{" "}
								Cualquier medio de acceso a los Servicios, tanto en plataforma
								móvil como en la plataforma web (www.talo.com.ar) que permita el
								intercambio de mensajes de datos.
							</li>
							<li>
								<strong>“Código QR”:</strong> Código de Respuesta Rápida (Quick
								Response Code).
							</li>
							<li>
								<strong>“Comisión”:</strong> Las tarifas a las que estarán
								sujetos los Servicios de conformidad con lo establecido en la
								Página de Comisiones aplicable.
							</li>
							<li>
								<strong>“Cuenta de Pago”:</strong> Las cuentas virtuales de
								libre disponibilidad que pueden tener Fondos en cuenta de pago,
								abiertas en Talo Pay para cada Usuario, que tienen como
								finalidad efectuar operaciones de débito y crédito con los
								Fondos en cuenta de pago que se encuentren disponibles,
								almacenar y reflejar la información privada y pública de Fondos
								en cuenta de pago así como de las distintas transacciones de
								Fondos en cuenta de pago realizadas por los Usuarios, al
								encontrarse ligada y referida a los diversos sistemas de
								compensaciones, depósitos y pagos utilizados por Talo Pay.
							</li>
							<li>
								<strong>“Entidad Financiera”:</strong> Aquellas sociedades
								habilitadas a funcionar como tal de conformidad con la Ley de
								Entidades Financieras No. 21.526, sus normas reglamentarias y
								modificatorias.
							</li>
							<li>
								<strong>“Fondos en cuenta de pago” o “Fondos”:</strong> Aquellos
								fondos o dinero electrónico que estén acreditados en la Cuenta
								de Pago del Usuario y estén contabilizados por Talo Pay en un
								registro electrónico conforme a la normativa aplicable.
							</li>
							<li>
								<strong>
									“Talo Pay”, “Nosotros”, “Nuestro”, “Sociedad”, y/o demás
									términos equivalentes, indistintamente:
								</strong>{" "}
								Talo Pay S.A.S., con domicilio en Monasterio 1375, Vicente
								Lopez, Vicente Lopez, Provincia de Buenos Aires, Argentina.
							</li>
							<li>
								<strong>“Página de Comisiones”:</strong> Será
								https://talo.com.ar/fees o en caso de que dicha página no se
								encuentre disponible, aquella otra similar o equivalente que la
								supla de tiempo en tiempo según Talo Pay lo comunique a sus
								Usuarios.
							</li>
							<li>
								<strong>“Servicios”:</strong> Los servicios que presta Talo Pay
								que consisten en la emisión, administración, redención y
								transmisión de Fondos y de apertura y administración de Cuentas
								de Pago, Procesamiento de Pagos, a través de aplicaciones
								informáticas, interfaces de páginas de internet o cualquier otro
								medio de comunicación electrónica o digital.
							</li>
							<li>
								<strong>“Usuario” o “Usuarios”:</strong> indistintamente, los
								Usuarios y beneficiarios de los Servicios de Talo Pay,
								incluyendo sin limitar a los usuarios registrados, así como
								cualquier visitante que utilice las Aplicaciones de Talo Pay.
							</li>
							<li>
								<strong>“Usuario”:</strong> El usuario que registre el Usuario,
								Talo Pay que le permite la utilización de la presente plataforma
								electrónica, las Aplicaciones, los Servicios y el almacenamiento
								de Fondos en la Cuenta de Pago. Cada Usuario almacenará la
								información personalizada de las distintas transacciones de
								Fondos realizadas por el Usuario, así como reflejará las
								acreditaciones que éste haya realizado a su Cuenta de Pago. De
								igual forma se entenderá por Usuario aquellas cuentas abiertas
								en Talo Pay para cada Usuario y que tienen como finalidad
								almacenar la información personalizada de las distintas
								transacciones realizadas por dichos Usuarios, así como reflejar
								los diferentes depósitos y Fondos de cada Cuenta de Pago.
							</li>
						</ul>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">2. Objeto</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 font-normal text-justify leading-relaxed">
							Las presentes Condiciones de Uso tienen por objeto regular la
							relación existente entre Talo Pay y el Usuario, siempre que Talo
							Pay permita el acceso al presente sitio web y la utilización de
							los Servicios disponibles en el mismo. El Usuario reconoce y
							acepta que esta plataforma electrónica le permite disponer de sus
							propios Fondos a través de su Cuenta de Pago, así como posibilitar
							la transferencia de Fondos a diferentes destinatarios. Al utilizar
							nuestros servicios, usted acepta estar sujeto a estos términos y
							condiciones.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">3. Consentimiento</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							De conformidad con los artículos 286, 288, 1105, 1106 y los que se
							consideren aplicables del Código Civil y Comercial de la Nación
							(el "Código"), los Términos de Uso y su aceptación por medios
							electrónicos o informáticos, constituyen un acuerdo válido y legal
							entre el Usuario y Talo Pay, en especial, pero no limitado a, los
							Servicios solicitados a través de nuestra plataforma. Usted
							entiende y reconoce que el acceso o uso de los Servicios, de
							cualquier otro servicio ofrecido por Talo Pay, o de nuestras
							Aplicaciones, implica la plena aceptación de estos Términos de
							Uso, de la política de privacidad de Talo Pay, y de cualesquiera
							otros documentos aplicables. Si estas Condiciones de Uso no son
							aceptables para el Usuario, deberá suspender inmediatamente el uso
							y abstenerse de acceder a las Aplicaciones de Talo Pay, así como
							abstenerse de utilizar cualquier Servicio o plataforma ofrecida
							por Talo Pay. El Usuario reconoce que la aceptación de estas
							Condiciones de Uso tiene el mismo efecto legal que si hubieran
							sido escritas a mano o firmadas digitalmente en un acuerdo, y
							acepta que (i) su consentimiento se obtendrá electrónicamente para
							todos los efectos legales, y (ii) que toda la información
							relacionada con el Servicio, incluyendo estas Condiciones de Uso,
							se proporcionará en formato digital. El Usuario consiente en
							asociar la(s) cuenta(s) virtuales y/o de pago que utilizará a
							través de las Aplicaciones proporcionadas por Talo Pay. El Usuario
							también declara y reconoce que todas las cuenta(s) a la vista y/o
							de pago asociadas son propiedad y están proporcionadas por la
							entidad declarada por el Usuario. Cualquier instrucción de pago o
							débito ordenada por el Usuario a través de las Aplicaciones
							proporcionadas por Talo Pay será procesada mientras esté vigente
							el consentimiento otorgado por el Usuario. Ocasionalmente, el
							Usuario tendrá la posibilidad de establecer y/o modificar los
							parámetros de uso de su(s) cuenta(s) a la vista y/o de pago en
							Talo Pay. Asimismo, el Usuario podrá desvincular la(s) cuenta(s)
							asociada(s) a través de Talo Pay, de conformidad con los presentes
							Términos de Uso y los procedimientos que se establezcan en el
							futuro.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							4. Funcionamiento de los servicios
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 font-normal text-justify leading-relaxed">
							A través del uso continuado a nuestras Aplicaciones, así como la
							aceptación de los presentes Términos de Uso, el Usuario reconoce y
							conviene que:
						</p>
						<ul className="list-disc list-outside pl-5 text-gray-700 dark:text-gray-400 text-justify text-h5 space-y-1 font-normal leading-relaxed">
							<li>
								El Usuario deberá estar registrado conforme a las políticas
								aplicables al Usuario que corresponda. Para poder registrarse
								como Usuario, deberá contar con la mayoría de edad requerida por
								el ordenamiento jurídico argentino para contratar y contar con
								la capacidad legal necesaria para obligarse y aceptar los
								Términos de Uso. Para el caso de personas jurídicas, únicamente
								aquellas personas que cuenten con las facultades necesarias para
								representar y obligar dicha persona jurídica frente a Talo Pay
								en la celebración de cualquier acto jurídico, previa validación
								de los requisitos correspondientes, podrán hacer uso de las
								funciones de nuestro sitio o nuestros Servicios.
							</li>
							<li>
								La finalidad de la Cuenta de Pago es la concertación de
								Servicios y actuar como medio de pago e intercambio de Fondos,
								por consiguiente el Usuario entiende y reconoce que: (i) Talo
								Pay no ofrece la concertación de contratos de depósito, y que
								por ende las acreditaciones de fondos en la Cuenta de Pago no
								constituyen un depósito en los términos del artículo 1390 y
								siguientes del Código ni plazos fijos de cualquier tipo, (ii)
								todo recurso, fondo y/o cantidad recibida por Talo Pay por parte
								del Usuario no devengará interés en favor del Usuario, premio o
								prima alguna, de cualquier naturaleza, (iii) Talo Pay no otorga
								préstamos ni financiamientos de cualquier tipo, (iv) Talo Pay no
								garantiza las obligaciones asumidas por el Usuario ni las
								operaciones realizadas por el mismo en la Cuenta de Pago. Talo
								Pay se reserva el derecho de cerrar los Usuarios y/o cualquier
								cuenta del Usuario e impedir el acceso a la Cuenta de Pago a
								cualquier Usuario que atente contra o desvirtúe la finalidad de
								la Cuenta de Pago, sin previo aviso ni derecho a indemnización
								alguna.
							</li>
							<li>
								Los Servicios de Talo Pay tienen como finalidad ser un medio
								para que los Usuarios de forma personal e independiente tengan
								la posibilidad de recibir y efectuar pagos inmediatos
								acreditando fondos en otras CVU o CBU. La plataforma permitirá
								al Usuario disponer de dichos fondos mediante la solicitud de
								procesamiento de pagos que efectuare.
							</li>
							<li>
								Cualquier Fondo y/o cantidad que Talo Pay reciba como depósito
								por parte de sus Usuarios, será reflejado en el CVU del Usuario
								respectivo con la finalidad de que dichos Usuarios estén en
								posibilidad de utilizar los Servicios y realizar transferencias,
								sin que Talo Pay deba abonar intereses, premio o prima alguna de
								cualquier naturaleza, por lo que el Usuario reconoce y conviene,
								en la medida que las leyes de la República Argentina así lo
								permitan, en renunciar a cualquier derecho que existiere o
								pudiere aducirse que existe, así como de reclamar cualquier tipo
								de derecho a Talo Pay o a cualquier persona, y libera a Talo Pay
								de cualquier responsabilidad al respecto. Talo Pay, o un tercero
								designado por Talo Pay, mantendrá la totalidad de los fondos y/o
								cualquier cantidad recibida de cualquier Usuario depositado –en
								todo momento– en cuentas a la vista en pesos en una o más
								Entidades Financieras hasta tanto sean entregados a un
								beneficiario o destinatario, sean transferidos a otra entidad
								facultada para participar en servicios de pago según corresponda
								o dicho Usuario adquiera Fondos o sean aplicados a la
								realización de operaciones con "fondos comunes de inversión" en
								la Argentina - sólo si esta última función estuviere habilitada
								por Talo Pay-, por lo que Talo Pay podrá transferir los Fondos
								de los Usuarios para realizar los actos correspondientes en la
								legislación aplicable.
							</li>
							<li>
								Las obligaciones, los Servicios y lo relativo a Fondos descritos
								en los presentes Términos de Uso, no se encuentran garantizados
								por ninguna subsidiaria, afiliada o tercero, incluyendo a
								cualquier autoridad o gobierno.
							</li>
							<li>
								Los presentes Términos de Uso cumplen con los requisitos legales
								correspondientes a la actividad de Talo Pay, incluyendo sin
								limitar los requisitos de capital mínimo previstos por las
								disposiciones aplicables, en caso de resultar aplicable.
							</li>
							<li>
								Las transacciones de los Usuarios se entenderán realizadas y por
								lo tanto eficaces, cuando estas hayan sido confirmadas por Talo
								Pay y no necesariamente cuando el Usuario las haya iniciado u
								ordenado.
							</li>
							<li>
								Las transacciones que se procesen en la cuenta de pago son
								irreversibles una vez ejecutadas.
							</li>
							<li>
								El Usuario reconoce y asume, en la medida que las leyes locales
								así lo permitan, cualquier riesgo que se derive de las
								operaciones realizadas con su cuenta de pago bajo los presentes
								Términos de Uso.
							</li>
							<li>
								El Usuario entiende y acepta que de conformidad con lo
								establecido por el artículo 1116 del Código, y de acuerdo con la
								naturaleza y característica de los Servicios, el derecho de
								revocar del Usuario no será aplicable, en la medida en que las
								leyes locales así lo permitan, respecto de cualquier operación,
								transferencias, adquisición o venta, realizado a través de las
								Aplicaciones.
							</li>
							<li>
								El Usuario reconoce que, aún y cuando Talo Pay se encuentra a la
								vanguardia en materia de seguridad tecnológica, existen diversos
								riesgos tecnológicos y cibernéticos, incluyendo el fraude, que
								son inherentes al uso de medios electrónicos o digitales en el
								sistema financiero, incluyendo la operación con cuentas de pago,
								de las Aplicaciones y al uso de plataformas tecnológicas en
								general de los cuales en ningún caso Talo Pay resultará
								responsable.
							</li>
							<li>
								El Usuario reconoce y acepta que la Cuenta de Pago, los Usuarios
								y, en general, los distintos Servicios de Talo Pay no cuentan
								con ningún tipo de seguro para el depósito de los Fondos del
								Usuario. Ni el Gobierno Federal ni las entidades de la
								administración pública podrán responsabilizarse o garantizar los
								Fondos de los Usuarios que sean utilizados en las operaciones
								que celebren con Talo Pay o frente a otros, así como tampoco
								asumir responsabilidad alguna por las obligaciones contraídas
								por Talo Pay.
							</li>
							<li>
								Mandato irrevocable. El Usuario reconoce y acepta que, al emitir
								solicitud de procesamiento de pago: (i) otorga un mandato
								irrevocable a Talo Pay para realizar las operaciones necesarias,
								con la finalidad de pagar por su cuenta y orden, para enviar o
								recibir dinero, o la ejecución de cualquier otro Servicio
								disponible, (ii) el mandato irrevocable otorgado por el Usuario
								implica la autorización a favor de Talo Pay para disponer en su
								nombre de los fondos disponibles en su cuenta de pago y
								transferirlos a la cuenta del destinatario instruido por el
								Usuario y (iii) autoriza irrevocablemente a Talo Pay a realizar
								cualesquiera operaciones (incluyendo, sin limitación,
								operaciones comerciales) que Talo Pay, a su solo criterio,
								considere oportunas para cumplir con la Orden de Gestión dentro
								de los plazos que se informen al Usuario, incluyendo el débito
								de cualquier interés, costo o cargo aplicable a la operación.
							</li>
							<li>
								Talo Pay podría reembolsar al Usuario, cuando éste así lo
								solicite, la cantidad equivalente a los Fondos que dicho Usuario
								disponga en los registros respectivos de Talo Pay, siempre y
								cuando las cantidades de dinero que haya recibido del Usuario no
								se hayan entregado a un beneficiario o destinatario, o bien,
								transferido a otra entidad facultada para participar en
								servicios de pago según corresponda, de acuerdo con lo dispuesto
								por los Términos de Uso y las órdenes de dicho Usuario.
							</li>
							<li>
								La legislación argentina o de cualquier otro país es susceptible
								de modificaciones, lo cual puede tener repercusiones en el uso o
								almacenamiento de Fondos en cuentas de pago e inclusive
								restricciones a dicho uso, por lo que Talo Pay no será
								responsable de ningún cambio en ese sentido, incluyendo aquellos
								relativos a las obligaciones legalmente exigibles a Talo Pay en
								la medida en que éstas lo permitan.
							</li>
							<li>
								La operación de la plataforma podría restringirse parcial o
								totalmente en cualquier momento, de manera programada o
								repentina. Esto podría ocasionar la no ejecución de operaciones
								pendientes, retrasos en la ejecución de órdenes o en la atención
								al Usuario, lo que pudiera causar un perjuicio económico al
								Usuario. Sin embargo, el Usuario, al aceptar estos Términos de
								Uso, acepta que Talo Pay no será responsable, bajo ningún motivo
								y en ninguna circunstancia, de los daños o perjuicios que
								situaciones similares pudieran causar. El Usuario se compromete
								a mantener indemne a Talo Pay de cualquier reclamo relacionado
								con estos u otros eventos similares.
							</li>
							<li>
								Las pérdidas por transacciones fraudulentas o accidentales
								pueden no ser recuperables. Una vez confirmadas, el Usuario
								estará obligado a cumplirlas. El Usuario entiende y acepta que
								Talo Pay no tiene responsabilidad alguna respecto de operaciones
								realizadas por los Usuarios que sean fraudulentas, accidentales
								o resultado de negligencia en el cuidado de sus llaves de acceso
								públicas o contraseñas privadas, conforme a lo establecido en la
								cláusula Sexta de estos Términos de Uso.
							</li>
							<li>
								Los Servicios serán prestados exclusivamente por Talo Pay, quien
								asume todas las responsabilidades y obligaciones conforme a
								estos Términos de Uso. Talo Pay es el titular o tiene derecho al
								uso de las interfaces, páginas de internet o cualquier otra
								dirección o enlace indicados en estos Términos de Uso, siendo
								Talo Pay el único responsable de su utilización frente a los
								Usuarios.
							</li>
						</ul>
						<p className="text-gray-700  dark:text-gray-400 text-h5 font-normal text-justify leading-relaxed">
							Cuando el Usuario realiza una operación, Talo Pay podrá retener el
							saldo disponible correspondiente a los Servicios solicitados, de
							manera que los fondos estén disponibles para completar la
							operación. Talo Pay mantiene los saldos en custodia durante el
							tiempo necesario hasta recibir una instrucción diferente de
							nuestros Usuarios. Dichos recursos estarán disponibles para los
							Servicios ofrecidos por Talo Pay a través del sitio o sus
							Aplicaciones, así como para cualquier transferencia entre Usuarios
							o a terceros, según se habilite. El Usuario acepta, reconoce y
							autoriza irrevocablemente a Talo Pay a retener o percibir
							cualquier impuesto, tasa, cargo o concepto que corresponda
							conforme a la operación realizada por el Usuario, al tipo de
							Usuario (persona humana o jurídica), o conforme a cualquier
							disposición establecida por la regulación aplicable, en los
							porcentajes o con las excepciones que resulten de la misma. En
							consecuencia, todo saldo en la cuenta de pago del Usuario será
							neto de cualquier impuesto, tasa, cargo o concepto que Talo Pay
							deba retener o percibir. Es responsabilidad del Usuario el
							cumplimiento de las obligaciones impositivas relacionadas con las
							operaciones realizadas a través de Talo Pay. Ni Talo Pay ni sus
							sociedades afiliadas, vinculadas, directores, gerentes, empleados,
							agentes, operarios, representantes y apoderados serán responsables
							por las obligaciones impositivas del Usuario ante las autoridades
							competentes. Talo Pay no brinda asesoramiento legal, financiero,
							impositivo ni de otra índole, por lo que el Usuario acepta y
							reconoce que deberá consultar con profesionales de su elección en
							relación con la aplicación de cualquier impuesto, tasa, cargo o
							concepto actual o futuro. Por seguridad, Talo Pay no asume
							responsabilidad alguna por cualquier transmisión de Fondos
							realizada según las instrucciones e información proporcionada por
							el Usuario. El Usuario reconoce y acepta que las transferencias
							desde su Cuenta de Pago, independientemente de la identidad del
							beneficiario o destinatario, son realizadas bajo su entera
							responsabilidad, y en ningún caso Talo Pay será responsable por
							este concepto. La única moneda de curso legal y liberatoria para
							quienes deseen utilizar los Servicios será la moneda de curso
							legal en Argentina. El Usuario podrá realizar transferencias
							inmediatas y/o pagos mediante la captura y lectura de Código QR de
							una entidad que actúe como aceptador de un esquema de
							transferencias electrónicas de fondos. Al realizar transferencias
							inmediatas y/o pagos con la captura y lectura de Código QR, se
							debitarán los fondos de la Cuenta de Pago. Si no hay fondos
							suficientes en la Cuenta de Pago, la operación será rechazada y se
							informará al Usuario el motivo del rechazo. El Usuario debe
							controlar el estado de su Cuenta de Pago antes de intentar una
							operación mediante lectura de Código QR. Cualquier acción del
							Usuario que implique la intervención de un tercero para disponer
							de fondos suficientes en la Cuenta de Pago será de exclusiva
							responsabilidad del Usuario, incluyendo el pago de comisiones
							devengadas y/o adeudadas como resultado de dichas operaciones. En
							caso de reversión de fondos por una transferencia o pago con
							Código QR, el Usuario recibirá en su Cuenta de Pago la
							acreditación total o parcial de fondos en Pesos Argentinos que el
							comercio haya decidido devolver mediante una transferencia
							inmediata inversa. El comercio notificará a Talo Pay la resolución
							de revertir fondos, y Talo Pay procederá a la reversión una vez
							recibida dicha resolución. El comercio podrá optar por realizar la
							devolución por transferencia, ya sea por solicitud del Usuario o
							por iniciativa propia. Talo Pay no será responsable por la acción
							o inacción del Usuario, del comercio o de cualquier tercero
							involucrado en el proceso de reversión de fondos. Los detalles de
							los pagos realizados mediante la captura y lectura del Código QR
							se reflejarán en la actividad de la Cuenta de Pago del Usuario. El
							Usuario es el único y exclusivo responsable del uso del servicio
							de transferencias y/o pagos mediante Código QR, incluyendo
							cualquier daño derivado. El Usuario acepta utilizarlo únicamente
							para transferencias inmediatas y/o pagos de bienes y/o servicios
							adquiridos en establecimientos físicos o en línea. El Usuario se
							compromete a no utilizarlo para fines ilícitos o distintos a los
							previstos. Talo Pay no será responsable por cualquier daño o
							perjuicio derivado de las transacciones realizadas o no realizadas
							mediante Código QR. El correcto funcionamiento del servicio de
							transferencias y/o pagos mediante Código QR, y de cualquier
							proceso de reversión, depende también de servicios prestados por
							terceros (como internet, datos móviles, dispositivos móviles y
							cámaras). El Usuario acepta que Talo Pay no tiene responsabilidad
							en relación con el cumplimiento de estos servicios de terceros. En
							particular, el Usuario es responsable de la conexión a Internet y
							del funcionamiento del dispositivo necesario para el servicio de
							lectura de Código QR. Talo Pay no asume la obligación de mantener
							el dispositivo utilizado para leer el Código QR en condiciones de
							usabilidad para todos los entornos operativos, incluyendo la
							interoperatividad con versiones actuales y futuras de software o
							hardware. El servicio de transferencias y/o pagos mediante Código
							QR podrá estar sujeto a promociones periódicas ofrecidas a los
							Usuarios, que podrán ser ofrecidas directamente por Talo Pay o
							terceros. Estos términos serán notificados oportunamente a los
							Usuarios y entrarán en vigor en la fecha establecida, conforme a
							la legislación aplicable en materia de promociones. Las
							promociones podrán ser ofrecidas, limitadas, suspendidas o
							terminadas a criterio del oferente, sin que esto implique un
							derecho adquirido por los Usuarios por el mero uso del Código QR o
							su participación en las promociones.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">5. Cumplimiento</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							La política de Prevención de Lavado de Dinero y Financiamiento al
							Terrorismo (“PLD/FT”) de Talo Pay se guía por las resoluciones de
							la Unidad de Información Financiera (“UIF”) y los criterios
							internacionales emitidos por el Grupo de Acción Financiera
							Internacional sobre el Blanqueo de Capitales. El Usuario reconoce
							que Talo Pay dará estricto cumplimiento a los requerimientos que
							cualquier autoridad competente le formule, lo que puede implicar
							requisitos adicionales a los establecidos en los presentes
							Términos de Uso, relacionados con el nivel de riesgo operativo,
							legal, reputacional, geográfico o por actividad preponderante,
							entre otros. Dichos requisitos deberán ser cumplidos para
							establecer y/o continuar con la prestación de los Servicios. El
							cumplimiento de los requerimientos en materia de datos personales
							estará sujeto a nuestra política de privacidad y a la legislación
							vigente. En caso de sospecha de incumplimiento de cualquier
							disposición de PLD/FT, nacional o internacional, por parte del
							Usuario o de la Cuenta de Pago en Talo Pay, el Usuario acepta que
							Talo Pay tiene el derecho de llevar a cabo cualquier acción de
							revisión y auditoría que consideremos necesaria, como solicitar y
							recabar información o documentación, y reportar cualquier
							actividad a las autoridades competentes. Talo Pay también puede
							tomar medidas o realizar actos contemplados implícita o
							explícitamente en los Términos de Uso o en la legislación
							aplicable, incluyendo la suspensión temporal o total de cualquier
							Usuario o Cuenta de Pago en Talo Pay o del acceso a las
							Aplicaciones. Como parte de la implementación de nuestra política
							de PLD/FT, Talo Pay se reserva el derecho de requerir información
							adicional para determinar o corroborar el perfil transaccional
							declarado u operado de sus Usuarios, así como el origen y destino
							de los fondos involucrados en las operaciones realizadas en
							nuestra plataforma. Si no se recibe la información necesaria, Talo
							Pay podrá suspender o terminar los Servicios y/o la relación
							contractual con el Usuario, incluyendo el acceso a nuestras
							Aplicaciones. Los Fondos que los Usuarios depositan en su Cuenta
							de Pago, o que terceros transfieren a la Cuenta de Pago del
							Usuario, deberán provenir de cuentas abiertas en una Entidad
							Financiera autorizada conforme a la normativa aplicable. Talo Pay
							establecerá interfaces de programación de aplicaciones
							informáticas estandarizadas para permitir la conectividad y acceso
							a otras interfaces desarrolladas o administradas por instituciones
							de tecnología financiera, Entidades Financieras y terceros
							especializados en tecnologías de la información, para compartir
							datos e información según la normativa aplicable. El Usuario
							autoriza expresamente a Talo Pay a compartir toda la información y
							documentación contenida en su legajo relativa a su identificación
							y el origen y licitud de los fondos, con otros sujetos obligados
							consignados en el artículo 20 de la Ley N° 25.246 o sus
							modificaciones, complementos o sustituciones.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">6. Seguridad</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							El buen uso, manejo y custodia de las firmas, llaves privadas,
							claves de acceso, contraseñas y cualquier otro dato de acceso a
							las Cuentas de Pago de los Usuarios son responsabilidad de estos.
							Talo Pay no será responsable de la falta de diligencia,
							negligencia o impericia a cargo de los Usuarios en el control y
							cuidado de estos datos, ni de las posibles consecuencias que esto
							pueda implicar, incluyendo, de manera enunciativa más no
							limitativa, posibles pérdidas de fondos o de información. Es
							responsabilidad del Usuario activar el método de autenticación de
							múltiples factores. Para ello, Talo Pay cuenta con tecnología que
							permite la implementación de generadores de contraseñas dinámicas
							mediante el uso de una aplicación compatible, a elección del
							Usuario. Entre las formas de autenticación de múltiples factores,
							además de sus credenciales o contraseñas de acceso, pueden existir
							códigos entregados a través del servicio de mensajes cortos o un
							mecanismo de autenticación de dos factores mediante alguna
							aplicación generadora de códigos dinámicos. Para efectos del
							párrafo anterior y en términos de la legislación aplicable, los
							mensajes de datos que el Usuario emita o comunique de manera
							posterior a su autenticación se tendrán por válidos para todos los
							efectos correspondientes, como si hubieran sido emitidos a través
							de dispositivos seguros y aprobados por el Usuario, y recibidos
							íntegramente y sin alteraciones en los servidores de Talo Pay. Por
							el solo hecho de acceder al sitio de Talo Pay y ordenar o celebrar
							cualquier operación, el Usuario se obliga a responder con la
							totalidad de su patrimonio por las obligaciones que le sean
							atribuibles o en las cuales haya incurrido. Talo Pay en ningún
							caso será responsable por el mal uso de las formas de
							autenticación que el Usuario decida utilizar. Talo Pay no se hace
							responsable de phishing, suplantación de identidad, ataques
							cibernéticos o cualquier otro tipo de ataque del que el Usuario
							sea víctima. Tampoco será responsable por actos de terceros que
							afecten las Aplicaciones, la red mundial de internet o cualquier
							otra plataforma de interconexión de forma general. Aunque Talo Pay
							busca en todo momento comunicar a sus Usuarios cualquier situación
							que pudiera afectar su Cuenta de Pago y permitir el uso y acceso a
							los Servicios a cualquier persona, Talo Pay se reserva el derecho,
							en cualquier caso y sin comunicación o explicación alguna, de
							prohibir el acceso a sus Aplicaciones a cualquier persona,
							incluyendo a los Usuarios, cualquier cuenta de los Usuarios y la
							Cuenta de Pago. El proceso de recuperación de número de Usuario o
							contraseña solo se realizará mediante los pasos establecidos por
							Talo Pay, siguiendo los más altos estándares de seguridad. Dicho
							proceso únicamente podrá ser iniciado por el Usuario y nunca será
							iniciado por parte de Talo Pay. En ninguna circunstancia, Talo Pay
							pedirá a sus Usuarios, por correo postal, correo electrónico ni
							por vía telefónica, que revelen las credenciales o contraseñas que
							utilizan para acceder a su Cuenta y/o Cuenta de Pago. Es
							responsabilidad del Usuario no compartir ni proporcionar sus datos
							de identificación, credenciales y llaves de acceso a terceros, así
							como asegurarse de que ingresa sus datos o credenciales en la
							dirección segura. El acceso al sitio de Talo Pay es
							responsabilidad del Usuario, y Talo Pay no será responsable de la
							seguridad de la conexión desde la cual acceda el Usuario. Derivado
							de lo anterior, es responsabilidad del Usuario notificar a Talo
							Pay si cree o sospecha que su Usuario, Cuenta de Pago o sus
							credenciales pueden estar comprometidos, o si un tercero está
							actuando en su nombre. Para ello, deberá dar aviso mediante un
							medio oficial de Talo Pay o enviar un correo electrónico a{" "}
							<a href="mailto:info@talo.com.ar" className="text-blue-600">
								info@talo.com.ar
							</a>
							. Además, el Usuario se obliga a tomar todas las medidas
							necesarias o convenientes que le solicite Talo Pay, así como a
							seguir los procedimientos establecidos por Talo Pay para recuperar
							el control de su Usuario, bajo pena de perderlo definitivamente.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">7. Avisos</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							El Usuario acepta que Talo Pay tomará como medio de contacto la
							dirección de correo electrónico con la que se ha dado de alta al
							abrir su Cuenta, por lo que esta será la vía a través de la cual
							recibirá avisos relacionados con los Servicios y la plataforma de
							Talo Pay. El Usuario acepta como válidas todas las comunicaciones
							intercambiadas por medios electrónicos, ya sea para la formación
							de acuerdos de voluntades o intercambio de notificaciones. Talo
							Pay también podrá utilizar la dirección de correo electrónico del
							Usuario para otras comunicaciones, incluidos los avisos con
							respecto a los Términos de Uso y sus operaciones, así como
							cualquier otra comunicación futura entre el Usuario y Talo Pay.
							Sin embargo, Talo Pay en ningún momento solicitará datos
							confidenciales (como, de manera enunciativa, contraseñas,
							balances, saldos, etc.) de sus Usuarios por medio de correo
							electrónico ni ningún medio distinto al sitio seguro identificado
							con el prefijo https. El Usuario deberá mantener su dirección de
							correo electrónico actualizada y notificar a Talo Pay de cualquier
							cambio a la misma. Talo Pay tendrá por válidas todas las
							comunicaciones enviadas a la dirección de correo electrónico
							registrada por parte del Usuario con independencia de su efectiva
							recepción.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">8. Condiciones de uso</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Talo Pay se reserva la facultad de establecer límites para operar
							mediante la Cuenta de Pago, los cuales estarán sujetos a la
							regulación aplicable en materia de prevención de fraude, lavado de
							activos, financiamiento del terrorismo y/o disposiciones de la
							legislación aplicable a la actividad de Talo Pay. En todos los
							casos, los Usuarios deberán respetar los límites y niveles de
							depósitos y de saldos mensuales determinados. Lo anterior no exime
							al Usuario de entregar o actualizar la información y/o
							documentación que Talo Pay, de tiempo en tiempo, solicite en
							cumplimiento del contenido de los manuales internos de control de
							riesgo y prevención de lavado de activos. Aun cuando Talo Pay se
							esfuerza por mantener los Servicios ofrecidos disponibles para
							toda persona, existen situaciones en las cuales, de acuerdo a
							diversas disposiciones aplicables, Talo Pay se verá en la
							obligación de prohibir el acceso o la apertura de Cuentas de Pago
							a ciertas personas que no cumplan con los requisitos referidos
							anteriormente, a juicio de Talo Pay. En ese sentido, Talo Pay se
							reserva el derecho a no abrir Cuentas de Pago y/o Usuarios o a
							terminar cuentas existentes, a personas que, a su más entera
							discreción, no satisfagan los requisitos anteriores, o que, por
							cualquier otra razón, no cumplan, a más entera discreción de Talo
							Pay, cualquiera de las situaciones que esta determine en atención
							a los intereses de Talo Pay y el cumplimiento de cualquier
							legislación nacional o internacional. De igual forma, Talo Pay se
							reserva el derecho a modificar, a su más entera discreción, los
							límites inferiores y superiores de los niveles, ya sea para que
							estos reflejen el poder adquisitivo de la moneda o bien para que
							se adapten a acontecimientos extraordinarios de carácter nacional
							o internacional que generen una desproporción en los niveles aquí
							mencionados, así como en las obligaciones y derechos de Talo Pay.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							9. Normas de transacción
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Los Usuarios reconocen y convienen que Talo Pay no es responsable
							por cualquier evento o circunstancia que afecte a cualquiera de
							las Entidades Financieras en las que se mantengan los Fondos de
							los Usuarios. Las operaciones con Fondos depositados en cuentas de
							pago pueden ser objeto de retrasos, contratiempos y/o condiciones
							que pueden afectar la transmisión o ejecución de estas. Aun cuando
							Talo Pay ha implementado la infraestructura necesaria para
							responder a las necesidades del mercado, no tiene control sobre
							fallas del hardware, software, electrónica o la congestión del
							mercado, por lo que el Usuario reconoce y acepta que en dichos
							casos Talo Pay no será responsable de los retrasos, dificultades o
							condiciones que afecten negativamente a la transmisión o ejecución
							de las órdenes. En aquellos casos en que consideremos que estamos
							frente a una operación inusual o por cualquier otra razón que a
							nuestra entera discreción consideremos pertinente, podremos
							requerir que confirme su solicitud vía telefónica o por cualquier
							otro medio de autenticación designado para tal efecto. Todos los
							Fondos disponibles en la cuenta de pago son operados por y entre
							nuestros Usuarios, por lo que son propiedad de estos, y Talo Pay
							únicamente proporciona las Aplicaciones para facilitar la
							operación.
						</p>
					</section>

					{/* <section className="space-y-4">
						<Heading3 className="font-semibold">
							10. Historial de cuentas y recibos de transacciones
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Talo Pay lleva un registro de cuentas sobre movimientos
							transaccionales que permite identificar los recursos y depósitos
							de cada Usuario y los saldos en la cuenta de pago disponibles al
							momento, el cual se mantendrá accesible al Usuario en todo
							momento, emitiéndose para tal efecto un comprobante de operaciones
							en tiempo real. En cualquier momento podrás descargar un
							comprobante de operaciones donde conste cada una de las
							operaciones realizadas en la sección de{" "}
							<a
								href="https://talo.com.ar/history"
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-600 hover:underline"
							>
								https://talo.com.ar/history
							</a>{" "}
							después de haber iniciado sesión en su Cuenta de Pago. Nos
							reservamos el derecho de ajustar, corregir o reconciliar sus
							registros e historial de movimientos cuando así sea necesario, lo
							que el Usuario reconoce y acepta, estando obligado a pagar
							cualquier saldo a su cargo. Nos esforzamos por proporcionar
							información precisa y verídica; sin embargo, es posible que exista
							algún error en el historial de los Usuarios, así como en
							cualquiera de sus comprobantes de operación. En caso de que exista
							un error, el Usuario cuenta con 30 días corridos para que nos lo
							dé a conocer a través de{" "}
							<a
								href="https://help.talo.com.ar"
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-600 hover:underline"
							>
								https://help.talo.com.ar
							</a>
							; pasado este periodo se entenderá que el Usuario reconoce y
							acepta el historial de su Usuario, sin poder iniciar una
							reclamación en fecha subsecuente. Asimismo, si el Usuario desea
							recibir un comprobante fiscal de los cobros que realiza Talo Pay,
							puede generar su propia factura a partir de su resumen de cuenta
							disponible en el siguiente vínculo:{" "}
							<a
								href="https://talo.com.ar/user/overview"
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-600 hover:underline"
							>
								https://talo.com.ar/user/overview
							</a>
							. Los Fondos son propiedad de los Usuarios, por lo que Talo Pay
							únicamente obtiene recursos derivados del cobro de Comisiones por
							la ejecución de las operaciones realizadas por los Usuarios
							conforme a los presentes Términos de Uso. Por consiguiente, Talo
							Pay únicamente puede proporcionarle un comprobante fiscal por el
							importe cobrado por concepto de Comisiones, tal como se establecen
							en la cláusula siguiente y en{" "}
							<a
								href="https://talo.com.ar/fees"
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-600 hover:underline"
							>
								https://talo.com.ar/fees
							</a>
							.
						</p>
					</section> */}
					<section className="space-y-4">
						<Heading3 className="font-semibold">
							10. Política de Privacidad
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Protegemos los datos personales de los Usuarios conforme a nuestra
							Política de Privacidad, disponible en nuestro sitio web{" "}
							<a
								href="https://talo.com.ar/privacy-policy"
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-600 hover:underline"
							>
								https://talo.com.ar/privacy-policy
							</a>{" "}
							. El Usuario entiende y acepta que no somos responsables por la
							pérdida, divulgación o alteración de los datos personales que
							surja como consecuencia de actos malintencionados, acceso no
							autorizado o cualquier otro tipo de incidente fuera de nuestro
							control razonable. El Usuario se compromete a mantener
							actualizados sus datos y a seguir las recomendaciones de seguridad
							proporcionadas para la protección de su información.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">11. Comisiones</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Cada Servicio ofrecido por Talo Pay está sujeto a una Comisión
							según se establece en la Página de Comisiones. Talo Pay se reserva
							el derecho de cambiar, modificar o aumentar las comisiones en
							cualquier momento, debiendo notificar previamente al Usuario
							respecto de cualquier modificación. Talo Pay se reserva el derecho
							a modificar, a su más entera discreción, los montos de las
							Comisiones establecidas, ya sea para que éstas reflejen el poder
							adquisitivo de la moneda o bien para que en caso de surgir
							acontecimientos extraordinarios de carácter nacional o
							internacional que generen una desproporción en los derechos y
							obligaciones de Talo Pay, éstas contribuyan a recuperar el
							equilibrio en las contraprestaciones aquí pactadas. Para más
							información sobre las Comisiones que cobra Talo Pay por el manejo
							de cuenta y por la ejecución de las transacciones, el Usuario
							puede consultar la Página de Comisiones. Es responsabilidad del
							Usuario consultar periódicamente la Página de Comisiones.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							12. Limitación de la responsabilidad
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Los Fondos en la cuenta de pago no están protegidos por ningún
							tipo de seguro, protección o garantía de depósito ni constituyen
							depósitos en una entidad financiera, ni cuentan con ninguna de las
							garantías que tales depósitos puedan gozar de acuerdo con la
							legislación y reglamentación aplicables en materia de depósitos en
							entidades financieras. Todas las operaciones o acreditaciones
							están sujetas a todas las leyes, reglamentos y normas aplicables
							que puedan regular el uso, transferencia, intercambio de Fondos y
							su operación en Talo Pay. Tu Cuenta de Pago no constituye una
							cuenta de depósito a la vista ni una cuenta bancaria de depósito
							regular. Nuestros Servicios no te pagarán intereses por los Fondos
							que mantengas. Todas tus operaciones con Talo Pay están sujetas a
							todas las leyes, reglamentos y normas aplicables que puedan
							regular el uso, transferencia, intercambio y tu operación en Talo
							Pay. Los Usuarios deberán solicitar la aprobación previa de Talo
							Pay si tienen intención de utilizar las Aplicaciones con
							propósitos de transmisión de dinero. Para recibir la aprobación,
							los Usuarios, además de cumplir con todos los requisitos
							inherentes a su nivel de operación, deben proporcionar prueba de
							licencia o de registro ante las autoridades correspondientes y/o
							cualquier entidad equivalente en el país en donde se encuentre el
							transmisor, según corresponda, así como prueba de políticas y
							procedimientos internos de PLD/FT que cumplan con la normativa
							aplicable. El Usuario reconoce que todas sus decisiones se
							realizan única y exclusivamente a nombre y cuenta propia y sin
							injerencia ni asesoría de ningún tercero. En consecuencia, cada
							Usuario está obligado a llevar a cabo una evaluación independiente
							respecto de los Fondos, las operaciones que puede llevar a cabo en
							la plataforma de Talo Pay, los Servicios otorgados por ésta y
							cualquier otra circunstancia relacionada con la misma. Talo Pay no
							será responsable en ningún caso frente al Usuario por cualquier
							tipo de decisiones tomadas por éste, independientemente de la
							actualización de un daño o perjuicio con motivo de dicha decisión.
							Cualquier operación que el Usuario realice en la plataforma es
							responsabilidad exclusiva del mismo. Talo Pay en ningún momento
							será responsable de aquellas fluctuaciones que pudieran resultar
							derivadas del poder adquisitivo de los Fondos en la cuenta de pago
							utilizados en las Aplicaciones.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">13. Plazo</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Los Términos de Uso estarán vigentes por un periodo indefinido,
							siempre y cuando exista relación jurídica entre Talo Pay y el
							Usuario, producto de la apertura de una Cuenta a nombre del
							Usuario. Una vez que el Usuario solicite el cierre de su Cuenta,
							los Términos de Uso permanecerán vigentes por un plazo adicional
							de 60 días hábiles.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							14. Usuarios del usuario
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Talo Pay no solicita ni requiere que nuestros Usuarios mantengan
							Fondos en sus Cuentas de Pago. El fondeo de las Cuentas de Pago es
							necesario exclusivamente para utilizar los Servicios que el
							Usuario desee. En caso de que la Cuenta de Pago del Usuario tenga
							algún balance negativo, el Usuario se obliga a reintegrar a Talo
							Pay los fondos necesarios de manera inmediata sin que deba mediar
							notificación al respecto. Talo Pay se reserva el derecho de
							suspender el uso de la Cuenta de Pago respectiva hasta tanto el
							saldo negativo sea reintegrado en su totalidad, así como de
							realizar todas las acciones de cobro que considere pertinentes. Al
							momento de registrar un Usuario en Talo Pay, el Usuario debe
							proporcionar ciertos datos de registro e información con el fin de
							verificar su identidad. Parte de esta información puede incluir
							datos personales o sensibles que recibirán el tratamiento que se
							establece en el aviso de privacidad de Talo Pay, accesible a
							través de este link: https://l.linklyhq.com/l/1uP3b. Al momento de
							completar el formulario de inscripción en línea, el Usuario afirma
							que proporciona información verdadera, precisa, actualizada y
							completa sobre sí mismo según lo requiere el formulario de
							inscripción; y además el Usuario es informado por el presente de
							su derecho a enmendar o actualizar los datos de registro y acepta
							mantener y actualizar los datos de registro para conservar la
							información íntegra, verdadera, precisa, actualizada y completa
							durante todo el tiempo en que es Usuario de Talo Pay. El registro
							de más de un Usuario para una misma persona humana o jurídica está
							prohibido, ya que tal hecho implica, de acuerdo con nuestros
							controles, la alteración parcial o total de información para poder
							crear otro registro. Lo anterior implica el cierre de ambos
							Usuarios y la imposibilidad de volver a operar en la plataforma de
							Talo Pay. Talo Pay utilizará los datos personales recabados de los
							Usuarios para llevar a cabo las actividades enfocadas al
							cumplimiento de las obligaciones derivadas de cualquier relación
							jurídica y comercial que resulte de los Términos de Uso, así como
							para proporcionar la mejor experiencia de Usuario posible. Para
							más información acerca del tratamiento y de los derechos que puede
							hacer valer, el Usuario puede acceder al aviso de privacidad
							integral en https://l.linklyhq.com/l/1uP3c. Talo Pay se reserva el
							derecho a activar o desactivar, de tiempo en tiempo y según la
							legislación aplicable lo requiera, los depósitos o transferencias
							desde cuentas abiertas a nombre de terceros distintos al Usuario
							titular de la Cuenta de Pago en Talo Pay. En ese sentido, y según
							lo requiera la legislación aplicable, las correspondientes
							transferencias deberán, o no, provenir de una cuenta a nombre del
							Usuario, pudiendo éstas ser devueltas a la cuenta de origen. Talo
							Pay se reserva el derecho de devolver cualquier transferencia
							efectuada por cualquier tercero en contravención a lo previsto en
							los Términos de Uso, la normativa aplicable y los requisitos que
							Talo Pay determine de tiempo en tiempo, así como de iniciar
							cualquier acción legal relacionada. El Usuario se compromete a
							notificar inmediatamente de cualquier uso no autorizado de su
							contraseña o identificación de Usuario, así como de cualquier otra
							violación de seguridad.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							15. Cierre de la cuenta
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							El Usuario puede cerrar en cualquier momento su Cuenta, para lo
							cual deberá crear un ticket de soporte en
							https://help.talo.com.ar/ seleccionando la opción que más se
							adecue a su solicitud. La Cuenta de Pago correspondiente se
							cerrará una vez que el Usuario haya cancelado todas las
							solicitudes de Servicios o Servicios pendientes y haya cumplido
							todas las operaciones que hubiere concertado y que no pudieren
							cancelarse, y el Usuario haya retirado todos sus fondos de Talo
							Pay según se establece en el presente numeral. Asimismo, el
							Usuario deberá completar un protocolo de seguridad para la
							protección de la Cuenta, según lo requiera de tiempo en tiempo
							Talo Pay. Talo Pay se reserva el derecho de suspender, cancelar o
							modificar cualquier aspecto de la Cuenta de Pago y/o Usuario en
							Talo Pay, o su disponibilidad, en cualquier momento sin previo
							aviso, sin tener responsabilidad alguna. Además, Talo Pay se
							reserva el derecho de suspender, modificar, desactivar o cancelar
							la Cuenta de Pago y/o Usuario de los Usuarios, así como su acceso
							a todos o parte de los servicios de Talo Pay de inmediato y sin
							previo aviso en los casos en que:
						</p>
						<ul className="list-disc list-outside pl-5 text-gray-700 dark:text-gray-400 text-justify text-h5 space-y-1 pt-4 font-normal leading-relaxed">
							<li>
								El Usuario viole cualquier disposición contractual o de los
								Términos de Uso;
							</li>
							<li>
								Se emita una orden judicial para el congelamiento de fondos;
							</li>
							<li>
								El Usuario o sus Cuentas de Pago están sujetos a algún litigio
								pendiente, investigación o procedimiento gubernamental;
							</li>
							<li>
								Se tenga una sospecha razonable de infracción por parte del
								Usuario a nuestra política de PLD/FT o ha incumplido con
								cualesquiera de las disposiciones aplicables;
							</li>
							<li>
								El Usuario no nos proporcione información verídica, actualizada
								y correcta;
							</li>
							<li>
								El Usuario fondee su Cuenta de Pago mediante el uso de técnicas
								o procesos fraudulentos o de manera ilegal;
							</li>
							<li>
								Si se hace de nuestro conocimiento que sus fondos son producto
								de una actividad ilícita o delictiva;
							</li>
							<li>
								Si, en la opinión razonable de Talo Pay, la Cuenta de Pago del
								Usuario interactúa con servicios de inversión fraudulentos o de
								rendimientos insostenibles;
							</li>
							<li>
								Por cualquier otra razón por la cual Talo Pay considere que la
								Cuenta de Pago del Usuario o las operaciones que ha realizado
								representan un riesgo para las operaciones de Talo Pay; y
							</li>
							<li>Por cualquier otra causa, a discreción de Talo Pay.</li>
						</ul>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							En aquellos casos en que, por cualquiera de las razones arriba
							mencionadas, Talo Pay proceda al cierre de una Cuenta de Pago,
							Talo Pay hará esfuerzos razonables, dentro de su control, para
							notificarle dicha situación al Usuario y en caso de que resulte
							aplicable iniciar el proceso de devolución de fondos remanentes en
							su Cuenta de Pago directamente a la cuenta de la Entidad
							Financiera que el Usuario tuviera registrada en Talo Pay. En el
							caso excepcional de que Talo Pay no cuente con los datos
							necesarios para realizar una transferencia a la cuenta de la
							Entidad Financiera del Usuario, Talo Pay notificará en la medida
							de sus posibilidades esta situación al Usuario y en caso de que
							éste no se encuentre, se estará conforme al procedimiento de
							cuentas sin movimientos previsto más adelante.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							16. Fondos en cuenta de pago no reclamados
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							En el caso de que una Cuenta de Pago de Talo Pay sea cerrada por
							cualquier razón, cualquier fondo remanente permanecerá en dicha
							Cuenta de Pago como propiedad no reclamada, hasta en tanto no se
							reclamen los Fondos. Si esto ocurre, Talo Pay hará esfuerzos
							razonables, dentro de su propio control, para notificarte la
							situación en la dirección de correo electrónico registrada. Los
							fondos remanentes depositados en las Cuentas de Pago que en el
							transcurso de tres años no hayan tenido movimiento por depósitos,
							redención, transmisión o consulta de saldo serán categorizados
							como “Fondos Inmovilizados” y se transferirán a una cuenta global
							de Talo Pay creada especialmente para esos efectos, quedando
							sujetos aquellos fondos a todas las variaciones de precio que por
							inflación y pérdida del poder adquisitivo de la moneda pudieran
							surgir y las comisiones que Talo Pay decida cargar por la
							administración de dichos fondos según se publique en la Página de
							Comisiones. En relación con lo anterior, no se considerarán
							movimientos aquellos relacionados con el cobro de Comisiones. El
							Usuario podrá hacer una designación de beneficiarios en la sección
							"editar perfil" al ingresar a su cuenta personal de Talo Pay. En
							relación con su Cuenta de Pago, el Usuario puede designar a
							múltiples beneficiarios o un beneficiario único y sustituirlos en
							cualquier momento, así como modificar el porcentaje
							correspondiente a cada uno de ellos y hasta el monto que, en su
							caso, determine la legislación aplicable. En caso de fallecimiento
							del Usuario, Talo Pay entregará el importe correspondiente a los
							Fondos a quienes el propio Usuario hubiese designado como
							beneficiarios, en el porcentaje estipulado para cada uno de ellos,
							siempre y cuando la designación realizada por el Usuario se
							encuentre de acuerdo con lo prescripto por el artículo 2445 del
							Código, previa diligencia, en términos de nuestras políticas de
							PLD/FT y acreditación de la personalidad. En aquellos casos que se
							presentase un deceso del titular de la Cuenta de Pago, Talo Pay se
							regirá conforme a lo dispuesto por el Código. En el caso de que
							una Cuenta de Pago de Talo Pay sea cerrada por alguna de las
							situaciones enumeradas en el artículo Décimo Quinto – Cierre de
							Cuentas de Pago de los presentes Términos de Uso, se procederá a
							transferir los fondos a una cuenta global de Talo Pay de la manera
							prevista en los presentes Términos de Uso.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							17. Errores del sistema
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Es posible que, debido a problemas tecnológicos, mantenimiento
							programado u otros factores, fuera o dentro de nuestro control, la
							Aplicación u otros Servicios se interrumpan temporalmente. Aunque
							haremos esfuerzos razonables para resolver dichos problemas, el
							Usuario acepta que no seremos responsables de los daños
							resultantes de dicha interrupción y el Usuario acepta renunciar a
							cualquier derecho o acción contra Talo Pay. En ningún caso Talo
							Pay será responsable de ningún daño, incluyendo, pero sin
							limitarse a, pérdidas, costos, gastos, pérdida de beneficios,
							pérdida de ingresos o expectativas de ganancias esperadas como
							resultado. Es posible que, debido a factores tecnológicos,
							mantenimientos programados u otros factores, fuera o dentro de
							nuestro control, la Aplicación u otros Servicios sean
							interrumpidos temporalmente. Si bien haremos lo posible para
							solventar en su caso dichos factores, el Usuario acepta que no
							somos responsables por los daños y perjuicios derivados de dicha
							interrupción y renuncia a cualquier derecho o acción que pudiere
							tener en contra de Talo Pay. En ningún caso Talo Pay será
							responsable de cualquier daño o perjuicio, incluyendo, sin
							limitación, todas las pérdidas, costos, gastos, pérdida de
							beneficios, pérdida de ingresos o expectativas de ganancias
							esperadas como consecuencia de la falla en el Servicio de Talo Pay
							o de las Aplicaciones o cualquier evento de seguridad, sin
							importar la causa, período de tiempo o afectación patrimonial. Aún
							y cuando Talo Pay se encuentra a la vanguardia en materia de
							seguridad tecnológica y realiza rutinariamente simulaciones para
							prevenir vulneraciones a la seguridad de nuestros Servicios y/o
							plataforma, existe la posibilidad de que las Aplicaciones y/o los
							Servicios sean víctimas de una vulneración en la cual se vean
							afectados fondos de los Usuarios. En caso de que Talo Pay sea
							víctima de algún evento en el que los fondos de los Usuarios sean
							comprometidos, Talo Pay procurará, mas no estará obligado a,
							reintegrar los fondos comprometidos para efectos de realizar una
							distribución entre los Usuarios afectados. En caso de que suceda
							un evento como el descrito en este párrafo, Talo Pay publicará un
							comunicado indicando los pasos a seguir. Talo Pay se reserva el
							derecho de revertir cualquier operación que haya sido efectuada
							como consecuencia de una interrupción en el servicio o falla en el
							funcionamiento de las comunicaciones electrónicas, instalaciones
							comerciales, instalaciones de almacenamiento, mecanismos de
							grabación u otros componentes de los Servicios. En estas
							circunstancias las operaciones se revisarán caso por caso, y Talo
							Pay te informará mediante la dirección de correo electrónico
							proporcionada por el Usuario, si alguna de tus operaciones está en
							esta situación.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							18. Riesgos que pueden surgir como consecuencias de las
							Transacciones
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							El Usuario reconoce que, con el solo uso de las Aplicaciones y los
							Servicios, así como la tenencia de Fondos en la cuenta de pago,
							está sujeto a diversos riesgos, dentro de los que se incluyen de
							forma enunciativa más no limitativa riesgos materiales, riesgos
							tecnológicos, riesgos de mercado y riesgos legales y por lo tanto
							consiente en que Talo Pay no será responsable de forma alguna por
							la actualización de estos. El Usuario reconoce y acepta que:
						</p>
						<ul className="list-disc list-outside pl-5 text-gray-700 dark:text-gray-400 text-justify text-h5 space-y-1 pt-4 font-normal leading-relaxed">
							<li>
								(i) los Fondos depositados en las cuentas de pago no constituyen
								depósitos en una entidad financiera y no cuentan con ninguna de
								las garantías que tales depósitos puedan gozar de acuerdo con
								las normas aplicables en materia de depósitos en entidades
								financieras.
							</li>
							<li>
								(ii) las operaciones con Fondos en la cuenta de pago son
								irreversibles y por lo tanto pueden llegar a ocasionar pérdidas
								por concepto de fraudes u operaciones accidentales (realizadas
								por un error de hecho a cargo del Usuario), mismas que podrán no
								ser recuperables.
							</li>
							<li>
								(iii) el uso de Fondos puede llevar a generar, por su propia
								naturaleza, un aumento en el riesgo de ataque cibernético, o
								fraudes.
							</li>
							<li>
								(iv) por su propia naturaleza, el uso de cuentas de pago implica
								que el Usuario podría no lograr disponer de éstos inmediatamente
								con motivo de fallas tecnológicas experimentadas por Talo Pay o
								cualquiera de sus proveedores.
							</li>
						</ul>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							19. Derechos de propiedad industrial
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Las Aplicaciones y los Servicios son propiedad de Talo Pay y/o
							afiliadas o sociedades pertenecientes al mismo grupo empresarial.
							Asimismo, Talo Pay es propietaria de los Servicios que ofrece. Nos
							reservamos todos los derechos sobre las Aplicaciones y cualquier
							tecnología asociada a las mismas. Cualquier creación, incluyendo
							pero no limitado a idea, invención, mejora, know-how, concepto,
							algoritmo, protocolo, datos, procesos, procedimientos, métodos,
							técnicas, protocolos, fórmulas, sistemas, herramientas,
							composiciones, códigos, software, prototipos, documentos,
							componentes o cualquier otra información que sea de la autoría de
							Talo Pay o de cualquier Usuario, pero exclusivamente como
							consecuencia del uso de las Aplicaciones de Talo Pay, sea o no
							patentable o susceptible de estar bajo la protección de derechos
							de autor, o cualquier tipo de derechos de propiedad intelectual o
							industrial, son propiedad de Talo Pay y/o subsidiaria y/o
							afiliada, teniendo Talo Pay el derecho de iniciar cualquier
							acción, o tomar cualquier medida, de cualquier naturaleza, para
							obtener su registro y la respectiva protección ante cualquier
							autoridad (incluyendo cualquier autoridad en materia de propiedad
							intelectual). Del mismo modo, cualquier obra derivada, tales como,
							de manera descriptiva más no limitativa, una traducción,
							modificación, mejora, personalización, adaptación, compilación o
							cualquier tipo de producto que use como origen cualquier elemento
							de nuestra plataforma será propiedad de Talo Pay y/o subsidiaria
							y/o afiliada, por lo que no concedemos ningún tipo de licencia, ni
							permiso para la reproducción de alguna obra, marca, diseño o
							código o permiso o licencia para su uso por cualquier tercero. En
							su caso, cualquier producción derivada será propiedad única y
							exclusiva de Talo Pay y/o sus subsidiarias y/o afiliadas. Todos
							los materiales y datos del sitio y cualquier otro sitio web
							propiedad, operado, licenciado o controlado por nosotros será
							propiedad intelectual de Talo Pay y/o subsidiaria y/o afiliada,
							por lo que nos reservamos todos los derechos sobre los mismos.
							Todos los materiales incluidos en las Aplicaciones, incluyendo sin
							limitar compilaciones de datos y software son propiedad de Talo
							Pay y/o subsidiaria y/o afiliada y están protegidos por las leyes
							de derechos de autor.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							20. Disposiciones adicionales
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Por el hecho de usar las Aplicaciones o de solicitar o recibir los
							Servicios de Talo Pay, cada uno de los Usuarios declara que (i)
							está facultado para aceptar y cumplir los Términos de Uso, (ii) el
							cumplimiento de los Términos de Uso no contraviene ninguna
							disposición que le sea aplicable, ni contrato o convenio alguno
							del que sea parte, (iii) ha obtenido y está en vigor cualquier
							autorización necesaria para cumplir con los Términos de Uso, (iv)
							no es parte de ningún litigio o controversia que pudiere tener
							efectos adversos respecto de sus obligaciones conforme a los
							Términos de Uso, así como respecto de los recursos aportados en
							las Aplicaciones, (v) tanto cualquier acuerdo de voluntades como
							los Términos de Uso constituyen obligaciones válidas y exigibles
							en su contra, conforme a sus términos, (vi) se encuentra
							legalmente posibilitado para otorgar su consentimiento vía
							electrónica respecto de los presentes Términos de uso y (vii) no
							existe una ley o regulación que restrinja o prohíba el uso de
							nuestros Servicios en la jurisdicción, país o estado donde el
							Usuario vive, se encuentra ubicado o donde se encuentren ubicados
							los servidores desde los cuales proviene su conexión. De igual
							forma el Usuario se obliga, siempre que las leyes de la República
							Argentina así lo permitan, a indemnizar a Talo Pay, así como a sus
							respectivos funcionarios, directores, colaboradores y empleados (y
							mantenerlos indemnes de los daños, costos, gastos o pérdidas,
							incluyendo honorarios razonables de abogados) por conflictos que
							surjan o se relacionen con el incumplimiento por parte del Usuario
							a cualquiera de sus obligaciones derivadas de los Términos de Uso
							o la legislación aplicable. Cada Usuario que acceda a las
							Aplicaciones o que utilice los Servicios de Talo Pay, se obliga,
							siempre que las leyes de la República Argentina así lo permitan, a
							indemnizar y mantener indemne a Talo Pay respecto de cualquier
							daño o perjuicio que afecte a Talo Pay, como consecuencia de
							cualquier hecho, acto u omisión del Usuario relacionado con el
							acceso a las Aplicaciones, los Servicios y conviene que para
							resarcirse de dichos daños o perjuicios, Talo Pay podrá utilizar
							los Fondos que se encuentren en cualquier Cuenta de Pago del
							Usuario o ejercer sus derechos en contra del Usuario conforme a la
							legislación aplicable, hasta tanto el daño y/o perjuicio sea
							resarcido. Si alguna disposición de los Términos de Uso es
							inválida o no exigible conforme a la ley aplicable, las
							disposiciones restantes continuarán en pleno vigor y efecto. Los
							presentes Términos de Uso y los derechos y obligaciones aquí
							previstos, no podrán ser transferidos o cedidos por los Usuarios
							bajo ninguna forma, pero nos reservamos el derecho, de ceder el
							presente, transferir o asignar la información que hemos recopilado
							de Usted con motivo de una cesión a subsidiarias, afiliadas,
							controladoras o entidades del mismo grupo empresarial o como
							consecuencia de cualquier otra operación. Para la interpretación,
							cumplimiento y ejecución de los Términos de Uso, las partes
							convienen que serán aplicables las leyes de la República Argentina
							y, en la medida que dichas leyes así lo permitan, se someten a la
							jurisdicción y competencia de los tribunales del Fuero Comercial
							de la Ciudad Autónoma de Buenos Aires, renunciando en este acto a
							aquellos que por ministerio de ley, fuero o residencia le pudieran
							corresponder, por razón de su domicilio o por cualquier otra
							causa. El Usuario acepta que los Servicios se consideran
							realizados en el territorio de la República Argentina y más
							específicamente, en la Ciudad Autónoma de Buenos Aires. El Usuario
							reconoce que una versión digital de los Términos de Uso, junto con
							la firma electrónica y la cadena de datos respectiva será
							admisible como instrumento probatorio en procedimientos judiciales
							o administrativos y que los títulos de las secciones de los
							Términos de Uso son sólo por conveniencia, y no regulan el
							significado o interpretación de cualquier disposición de los
							Términos de Uso. Para la conveniencia de las partes, los Términos
							de Uso podrán ser firmados de manera electrónica o digital; el
							ejemplar que posea cada una de las partes, ya sea en formato
							electrónico, escaneado o por cualquier medio digital se
							considerará un original, y los dos que, en su conjunto,
							constituirán un acuerdo vinculante para ambas partes. El Usuario
							manifiesta que ha leído las disposiciones de los Términos de Uso,
							y que entiende el alcance de sus obligaciones y derechos derivados
							de los mismos, asumiendo así las obligaciones que derivan de su
							consentimiento, no obrando error de derecho o de hecho, violencia,
							dolo, mala fe, engaño, lesión, incapacidad o cualquier otro vicio
							que pudiera afectar el consentimiento.
						</p>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Nos reservamos el derecho de modificar estos términos en cualquier
							momento. Las modificaciones se notificarán a los usuarios mediante
							un aviso visible en nuestras Aplicaciones o por correo
							electrónico, y entrarán en vigor a partir de la fecha indicada en
							el aviso. Es responsabilidad del Usuario revisar periódicamente
							los Términos de Uso para mantenerse informado sobre cualquier
							cambio. El uso continuado de las Aplicaciones o Servicios después
							de la publicación de cualquier modificación constituirá la
							aceptación de los nuevos términos.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							21. Asesoramiento financiero
						</Heading3>

						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							El Usuario entiende y acepta que todas operaciones efectuadas
							mediante la Cuenta de Pago, incluyendo, pero no limitado a
							decisiones de compra y contratación de servicios, son realizadas
							exclusivamente por el Usuario y, en consecuencia, el Usuario está
							obligado a llevar a cabo una evaluación independiente respecto de
							estas. Talo Pay no presta asesoría de ningún tipo ni se
							responsabiliza por ello frente al Usuario respecto de cualquier
							operación concertada por éste mediante la Cuenta de Pago y/o las
							Aplicaciones. Al respecto, el Usuario reconoce y acepta que:
						</p>
						<ul className="list-disc list-outside pl-5 text-gray-700 dark:text-gray-400 text-justify text-h5 space-y-1 pt-4 font-normal leading-relaxed">
							<li>
								(i) los Fondos en la cuenta de pago no constituyen depósitos en
								una entidad financiera, ni cuentan con ninguna de las garantías
								de que pueden gozar dichos depósitos de acuerdo con la
								legislación y normativa aplicable en materia de depósitos de
								entidades financieras
							</li>
							<li>
								(ii) las acreditaciones en la Cuenta de Pago no cuentan con
								ningún tipo de seguro de depósito ni garantía,
							</li>
							<li>
								(iii) Talo Pay se limita a ofrecer servicios de pago y no se
								encuentra autorizado a operar como Entidades Financiera por el
								Banco Central de la República Argentina;
							</li>
							<li>
								(iv) existen riesgos tecnológicos, cibernéticos y de fraude
								inherentes, y
							</li>
							<li>
								(v) las transacciones únicamente se entienden realizadas cuando
								han sido confirmadas por Talo Pay.
							</li>
						</ul>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							22. Información de contacto
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Para cualquier consulta o comunicación relacionada con los
							Servicios, el Usuario debe enviar un correo electrónico a{" "}
							<a href="mailto:info@talo.com.ar" className="text-blue-600">
								info@talo.com.ar
							</a>
							. Las comunicaciones oficiales se realizarán exclusivamente a
							través de este correo electrónico y de nuestras redes sociales
							oficiales, las cuales están enlazadas en nuestra página web.
							Cualquier otra forma de comunicación no será considerada oficial.
						</p>
					</section>

					<section className="space-y-4">
						<Heading3 className="font-semibold">
							23. Servicios de terceros
						</Heading3>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Talo Pay utiliza los servicios de Polux Capitals S.A., quien actúa
							como nuestro proveedor bancario y proveedor de servicios de pago
							(PSP). Polux Capitals S.A. es responsable del manejo de activos y
							cuentas de los usuarios que operan a través de Talo Pay. Esto
							incluye la custodia de los fondos, así como la recaudación de
							impuestos aplicables a las transacciones realizadas mediante
							nuestra plataforma.
						</p>
						<p className="text-gray-700 dark:text-gray-400 text-h5 text-justify font-normal leading-relaxed">
							Polux Capitals S.A. es el encargado de todas las operaciones
							financieras, incluyendo la gestión de pagos y cobros. Las
							transacciones realizadas a través de nuestra plataforma son
							procesadas y gestionadas por Polux Capitals S.A., y cualquier
							problema relacionado con la gestión de fondos deberá ser dirigido
							a ellos. Puedes revisar los términos y condiciones de Polux
							Capitals S.A. en el siguiente enlace:{" "}
							<a
								href="/terms-of-service/polux"
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-600 hover:underline"
							>
								Términos y Condiciones de Polux Capitals S.A.
							</a>
							.
						</p>
					</section>
				</div>
				<Button
					variant={isBottom ? "default" : "outline"}
					className="fixed right-10 bottom-10"
					size="xl"
					onClick={isBottom ? scrollToTop : scrollToBottom}
				>
					{isBottom ? <ArrowUpToLine /> : <ArrowDownToLine />}
				</Button>
			</CenteredBody>
		</LandingLayout>
	)
}

export default TermsOfService
