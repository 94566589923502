import { FiatAddressDetails } from "../pages/withdraw/withdraw-wizard-types"
import { apiPrivate } from "./interceptors"
import bank_list from "../lib/psp_bank_id.json"
import { findImgForBankInJson } from "../lib/utils"

export type BankInfoResponse = {
	titular: {
		tipo_persona: string
		cuit: string
		nombre: string
	}
	alias: {
		valor: string
		valor_original: string
	}
	cuenta: {
		tipo_cta: string
		nro_bco: string
		nro_psp?: string
		cta_activa: boolean
		nro_cbu: string
	}
}

export async function getAddressDetails(address: string): Promise<FiatAddressDetails> {
	const res = await apiPrivate.get(`/cvu/${address}/bank-info`);

	const bankInfo = res.data.data as BankInfoResponse;
  
	if (res.data.code !== 200) {
	  return {
		name: "No pudimos encontrar informacion de esta cuenta",
		cbu: address,
		account_type: "",
		active_account: false,
		address: "",
		alias: "",
		creation_timestamp: "",
		cuit: "",
		cvu_type: "",
		entity_type: "",
		last_modified_timestamp: "",
		last_used_timestamp: "",
		user_id: "",
		icon_img: undefined,
	  } as FiatAddressDetails;
	}
  
	let icon_img;
	try {
	  icon_img = findImgForBankInJson(
		bankInfo.cuenta.nro_psp ?? bankInfo.cuenta.nro_bco ?? "",
		bank_list
	  );
	} catch (e) {}
  
	return mapBankInfoResponseToFiatAddressDetails(bankInfo, address, icon_img);
  }

export function mapBankInfoResponseToFiatAddressDetails(
	data: BankInfoResponse,
	address: string,
	icon_img?: string
  ): FiatAddressDetails {
	return {
	  name: data.titular.nombre,
	  cbu: data.cuenta.nro_cbu,
	  account_type: data.cuenta.tipo_cta,
	  active_account: data.cuenta.cta_activa,
	  address: address,
	  alias: data.alias.valor,
	  creation_timestamp: "",
	  cuit: data.titular.cuit,
	  cvu_type: "",
	  entity_type: data.titular.tipo_persona,
	  last_modified_timestamp: "",
	  last_used_timestamp: "",
	  user_id: "",
	  icon_img: icon_img,
	} as FiatAddressDetails;
  }

export async function getUserCashoutCvus({ user_id }: { user_id: string }) {
	const res = await apiPrivate.get(`/cvu/?user_id=${user_id}&type=address_book`)
	let address_book = res.data.data.address_book

	address_book = address_book.map((address: any) => {
		if (address.psp_id) {
			return {
				address: address.address,
				alias: address.alias,
				cuit: address.cuit,
				name: address.name,
				network: "POLLUX",
				currency: "ARS",
				psp_id: address.psp_id,
				icon_img: findImgForBankInJson(address.psp_id, bank_list),
			}
		}

		if (address.bank_id) {
			return {
				address: address.address,
				alias: address.alias,
				name: address.name,
				cuit: address.cuit,
				network: "POLLUX",
				currency: "ARS",
				bank_id: address.bank_id,
				icon_img: findImgForBankInJson(address.bank_id, bank_list),
			}
		}

		return {
			address: address.address,
			alias: address.name,
			network: "POLLUX",
			currency: "ARS",
		}
	})

	return address_book
}
